import React from 'react';
import ForgetPasswordForm_Bloc from '../ForgetPasswordForm';
const ForgetPassword_Bloc = () => {
  return (
    <div className='text-center'>
      <h5 className='mb-0'> Forgot your password?</h5>
      <small>Enter your email and we'll send you a reset link.</small>
      <ForgetPasswordForm_Bloc />
    </div>
  );
};

export default ForgetPassword_Bloc;
