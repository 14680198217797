export const SOLUTIONS = {
  STUDIO: 'studio',
  DEVKIT: 'devkit',
  DEFENDER: 'defender',
};
export const SOLUTION_OPTIONS = [
  { value: SOLUTIONS.STUDIO, label: 'Studio' },
  { value: SOLUTIONS.DEVKIT, label: 'Devkit' },
  { value: SOLUTIONS.DEFENDER, label: 'Defender' },
];

export const USER_TYPES = {
  DEVELOPER: 'developer',
  RESEARCHER: 'researcher',
  ANALYST: 'analyst',
  INVESTOR: 'investor',
  CRYPTO_ENTHUSIAST: 'crypto-enthusiast',
  TRADER: 'trader',
  REGULATOR: 'regulator',
  INVESTIGATOR: 'investigator',
};

export const USER_TYPE_OPTIONS = [
  { value: USER_TYPES.DEVELOPER, label: 'Developer' },
  { value: USER_TYPES.RESEARCHER, label: 'Researcher' },
  { value: USER_TYPES.ANALYST, label: 'Analyst' },
  { value: USER_TYPES.INVESTOR, label: 'Investor' },
  { value: USER_TYPES.CRYPTO_ENTHUSIAST, label: 'Crypto Enthusiast' },
  { value: USER_TYPES.TRADER, label: 'Trader' },
  { value: USER_TYPES.REGULATOR, label: 'Regulator' },
  { value: USER_TYPES.INVESTIGATOR, label: 'Investigator' },
];

export const USER_TYPE_TO_SOLUTION = {
  [USER_TYPES.DEVELOPER]: SOLUTIONS.DEVKIT,
  [USER_TYPES.RESEARCHER]: SOLUTIONS.STUDIO,
  [USER_TYPES.ANALYST]: SOLUTIONS.STUDIO,
  [USER_TYPES.INVESTOR]: SOLUTIONS.STUDIO,
  [USER_TYPES.CRYPTO_ENTHUSIAST]: SOLUTIONS.STUDIO,
  [USER_TYPES.TRADER]: SOLUTIONS.STUDIO,
  [USER_TYPES.REGULATOR]: SOLUTIONS.DEFENDER,
  [USER_TYPES.INVESTIGATOR]: SOLUTIONS.DEFENDER,
};
