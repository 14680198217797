import { decoderServiceDocs } from 'blockscope/components/documentation/developers/configs/docs/services/decoder';
import { labelsServiceDocs } from 'blockscope/components/documentation/developers/configs/docs/services/labels';
import { riskServiceDocs } from 'blockscope/components/documentation/developers/configs/docs/services/risk';

export const FE_DOCUMENTATION_BROWSER_ROUTES = {
  DECODER: {
    TRANSACTION: '/docs/api/v2/decoder/transaction',
  },
  LABELS: {
    ADDRESSES: '/docs/api/v2/labels/address',
  },
  RISK: {
    ADDRESSES: '/docs/api/v2/risk/address',
  },
};

export const DEV_API_SERVICES = {
  DECODER: decoderServiceDocs,
  LABELS: labelsServiceDocs,
  RISK: riskServiceDocs,
};
