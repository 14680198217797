import { ORG_SERVICES } from 'blockscope/static/blockscopeServices';
import { SERVICE_CHAINS } from 'blockscope/static/supportedChains';

import { API_CALL_METHODS } from 'blockscope/components/documentation/developers/configs/code';

const FE_DOCUMENTATION_BROWSER_ROUTES = {
  DECODER: {
    TRANSACTION: '/docs/api/v2/decoder/transaction',
  },
  LABELS: {
    ADDRESSES: '/docs/api/v2/labels/address',
  },
  RISK: {
    ADDRESSES: '/docs/api/v2/risk/address',
  },
};

export const decoderServiceDocs = {
  TRANSACTION: {
    name: 'Decode Transaction',
    method: API_CALL_METHODS.POST,
    api_url: '/dev-api/v2/decoder/transaction',
    path: FE_DOCUMENTATION_BROWSER_ROUTES.DECODER.TRANSACTION,
    description:
      'Get all the relevant decoded information about a transaction from a single transaction hash',
    chains: SERVICE_CHAINS[ORG_SERVICES.TRANSACTION_DECODER],
    inputs: [
      {
        id: 0,
        name: 'transaction',
        type: 'string',
        value:
          '0xb5c8bd9430b6cc87a0e2fe110ece6bf527fa4f170a4bc8cd032f768fc5219838',
        enum: null,
        required: true,
        description: 'The hash of the transaction you wish to decode',
        editable: true,
      },
      {
        id: 1,
        name: 'chain',
        type: 'string enum',
        value: 'ethereum',
        enum: SERVICE_CHAINS[ORG_SERVICES.TRANSACTION_DECODER],
        required: true,
        description: 'The chain that the transaction took place on',
        editable: true,
      },
    ],
    responseSchema: [
      {
        name: 'decodedTxn',
        type: 'object',
        children: [
          {
            name: 'txReciept',
            type: 'object',
            description: 'Raw transaction receipt from the blockchain network',
          },
          {
            name: 'decodedCallStack',
            type: 'object',
            description:
              'Transaction call stack decoded wherever possible. Raw calls are provided for all calls, decoded calls are provided for calls that can be decoded',
          },
          {
            name: 'tokenTransfers',
            type: 'object array',
            description:
              'An array of every token transfer that occured during this transaction. Includes ERC-20, ERC-721, ERC-1155 tokens.',
          },
          {
            name: 'nativeTransfers',
            type: 'object array',
            description:
              'An array of every native token transfers that occured during this transaction',
          },
        ],
      },
    ],
  },
};
