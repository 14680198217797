import { API_CALL_METHODS } from 'blockscope/components/documentation/developers/configs/code';
import { useDocumentStore } from 'blockscope/components/documentation/developers/state/documentStore';

function paramFunction(paramArray) {
  let paramStrings = [];
  let parameterCount = paramArray.length;
  paramArray.forEach((param, index) => {
    paramStrings.push(
      `${index + 1 === parameterCount ? '' : ''}'${param.name}':${
        param.type === 'string array' ? '[' : ''
      }'${param.value}'${param.type === 'string array' ? ']' : ''}`
    );
  });
  return `${paramStrings.map((string) => {
    return string;
  })}`;
}

export function createJqueryCodeSnippet(
  callMethod = API_CALL_METHODS.GET,
  apiUrl = 'http://www.example.com/api/'
) {
  const apiKey = useDocumentStore((state) => state.apiKeyState);
  const parameterState = useDocumentStore((state) => state.parameterState);
  return `const settings = {
  async: true,
  crossDomain: true,
  url: '${apiUrl}',
  method: '${callMethod.value}',
  headers: {
    accept: 'application/json',
    'content-type': 'application/json',
    'X-API-KEY': '${apiKey}'
  },
  processData: false,
  data: {${paramFunction(parameterState)}}
};

$.ajax(settings).done(function (response) {
  console.log(response);
});`;
}
