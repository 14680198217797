import React, { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { topNavbarBreakpoint } from 'config';

import blockscopeLogo from 'blockscope/assets/logo/blockscope-green.svg';

import NavbarTopDropDownMenus from 'blockscope/components/navbar/top/NavbarTopDropDownMenus';
import LandingRightSideNavItem from 'blockscope/components/pages/landing/LandingRightSideNavItem';

const NavbarTallyPage = () => {
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  return (
    <Navbar
      variant={'dark'}
      fixed='top'
      expand={topNavbarBreakpoint}
      className={'bg-dark'}
    >
      <Container>
        <Navbar.Brand className='text-white dark__text-white' as={Link} to='/'>
          <img className='me-2' src={blockscopeLogo} alt='Logo' width={38} />
          Blockscope
        </Navbar.Brand>

        <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />

        <Navbar.Collapse className='scrollbar'>
          <Nav>
            <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} />
          </Nav>
          <LandingRightSideNavItem />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarTallyPage;
