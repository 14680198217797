import React, { useEffect, useState } from 'react';
import { Button, Card, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';

const OrgSelectionDropdown = ({ orgReducer }) => {
  const [show, setShow] = useState(null);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setShow(false);
    });
  }, []);

  return (
    <Dropdown navbar={true} as='li' show={show} onToggle={() => setShow(!show)}>
      <Dropdown.Toggle
        bsPrefix='toggle'
        className='nav-link px-2'
        style={{ all: 'unset', cursor: 'pointer' }}
        size='sm'
      >
        {orgReducer && orgReducer?.orgDetails?.org ? (
          <span className='me-1'>
            {`${orgReducer.orgDetails.org.orgName}`.toUpperCase()}
          </span>
        ) : null}
        <FontAwesomeIcon icon={'caret-down'} className='fs-0' />
      </Dropdown.Toggle>

      <Dropdown.Menu
        className='dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg'
        show={show}
      >
        <SimpleBarReact style={{ width: '16.7rem' }}>
          <Card className='  dropdown-menu-end shadow-none'>
            <Card.Body className=''>
              <div className='d-grid gap-2'>
                <Button
                  variant='falcon-primary'
                  as={Link}
                  to='/select-org'
                  size='sm'
                >
                  + Create Workspace
                </Button>
                <Button
                  variant='falcon-default'
                  size='sm'
                  className='btn btn-block'
                  as={Link}
                  to='/org-settings'
                >
                  Workspace Settings
                </Button>
                <Button
                  variant='falcon-default'
                  size='sm'
                  className='btn btn-block'
                  as={Link}
                  to='/select-org'
                >
                  Switch Workspace
                </Button>
              </div>
            </Card.Body>
          </Card>
        </SimpleBarReact>
      </Dropdown.Menu>
    </Dropdown>
  );
};

OrgSelectionDropdown.propTypes = {
  orgReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  orgReducer: state.orgReducer,
});

export default connect(mapStateToProps, {})(OrgSelectionDropdown);
