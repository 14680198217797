export const blockscopeDefaultTokenFA = {
  prefix: 'fas',
  iconName: 'blockscope-coin',
  icon: [
    416,
    416,
    [],
    'e001',
    'M326.7 403.7c-22.1 8-45.9 12.3-70.7 12.3s-48.7-4.4-70.7-12.3l-.8-.3c-30-11-56.8-28.7-78.6-51.4C70 314.6 48 263.9 48 208 48 93.1 141.1 0 256 0s208 93.1 208 208c0 55.9-22 106.6-57.9 144-1 1-2 2.1-3 3.1a208.8 208.8 0 0 1-76.3 48.6zM256 91.9c-11.1 0-20.1 9-20.1 20.1v6q-8.4 1.8-15.9 5.1c-15 6.8-27.9 19.4-31.1 37.7-1.8 10.2-.8 20 3.4 29 4.2 8.8 10.7 15 17.3 19.5 11.6 7.9 26.9 12.5 38.6 16l2.2.7c13.9 4.2 23.4 7.4 29.3 11.7 2.5 1.8 3.4 3.2 3.7 4s.9 2.6.2 6.7c-.6 3.5-2.5 6.4-8 8.8-6.1 2.6-16 3.9-28.8 1.9-6-1-16.7-4.6-26.2-7.9-2.2-.7-4.3-1.5-6.4-2.1-10.5-3.5-21.8 2.2-25.3 12.7s2.2 21.8 12.7 25.3c1.2.4 2.7.9 4.4 1.5 7.9 2.7 20.3 6.9 29.8 9.1v6.4c0 11.1 9 20.1 20.1 20.1s20.1-9 20.1-20.1v-5.5c5.3-1 10.5-2.5 15.4-4.6 15.7-6.7 28.4-19.7 31.6-38.7 1.8-10.4 1-20.3-3-29.4-3.9-9-10.2-15.6-16.9-20.5-12.2-8.8-28.3-13.7-40.4-17.4l-.8-.2c-14.2-4.3-23.8-7.3-29.9-11.4-2.6-1.8-3.4-3-3.6-3.5-.2-.3-.7-1.6-.1-5 .3-1.9 1.9-5.2 8.2-8.1 6.4-2.9 16.4-4.5 28.6-2.6 4.3.7 17.9 3.3 21.7 4.3 10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-4.4-1.2-14.4-3.2-21-4.4v-6.3c0-11.1-9-20.1-20.1-20.1z',
  ],
};
