import { sha256 } from 'ethers';

// TRON to EVM Address Conversion
export function tronToEvmAddress(tronAddress) {
  const decoded = base58Decode(tronAddress);
  const publicKey = decoded.slice(1, -4); // Exclude checksum
  const evmAddress = publicKey.reduce((acc, val) => {
    return `${acc}${val.toString(16).padStart(2, '0')}`;
  }, '0x');

  return evmAddress;
}

// EVM to TRON Address Conversion
export function evmToTronAddress(evmAddress) {
  const addr = `41${evmAddress.slice(2)}`;
  const doubleSha256 = sha256(sha256(`0x${addr}`));
  const checkSum = doubleSha256.slice(2, 10);
  const publicKey = hexToUint8Array(addr + checkSum);
  const tronAddress = base58Encode(publicKey);
  return tronAddress;
}

// Base58 Encode
function base58Encode(bytes) {
  // Bitcoin Base58 encoding alphabet
  const alphabet = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
  let encoded = '';
  let num = bytesToBigInt(bytes);
  while (num > 0n) {
    const remainder = num % 58n;
    encoded = alphabet[Number(remainder)] + encoded;
    num = num / 58n;
  }
  return encoded;
}

// Base58 Decode
function base58Decode(encoded) {
  // Bitcoin Base58 decoding alphabet
  const alphabet = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
  let num = 0n;
  for (let i = 0; i < encoded.length; i++) {
    const char = encoded[i];
    const index = alphabet.indexOf(char);
    if (index === -1) {
      throw new Error('Invalid Base58 character');
    }
    num = num * 58n + BigInt(index);
  }
  const hex = num.toString(16);
  const padding = (encoded.match(/^T*/) || [''])[0].length - 1;
  return hexToUint8Array(hex.padStart(padding + hex.length, '0'));
}

// Helper function: Convert bytes to BigInt
function bytesToBigInt(bytes) {
  let result = 0n;
  for (let i = 0; i < bytes.length; i++) {
    result = (result << 8n) | BigInt(bytes[i]);
  }
  return result;
}

// Helper function: Convert hexadecimal string to Uint8Array
function hexToUint8Array(hexString) {
  const array = new Uint8Array(hexString.length / 2);
  for (let i = 0; i < hexString.length; i += 2) {
    array[i / 2] = parseInt(hexString.substr(i, 2), 16);
  }
  return array;
}

// // Example usage
// const tronAddr = 'TYpLdCcwvmx8cyVnPEm2pTSrf8K1E6sEXK';
// const evmAddr = '0x3f5ce5fbfe3e9af3971dd833d26ba9b5c936f0be';
