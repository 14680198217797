import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

// Actions
import { selectOrg_Action } from '../../stateManagement/redux/actions/orgActions';

const FetchOrgRoute = ({
  // parent params
  children,

  // self params
  addOrgToURL,

  // reducer
  orgReducer: { orgDetails, orgDetailsLoader },

  // action
  selectOrg_Action,
}) => {
  // currentOrg var is empty at start
  const [currentOrg, setCurrentOrg] = useState('');
  const [loadingCount, setLoadingCount] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    setLoadingCount(loadingCount + 1);
  }, [orgDetailsLoader]);

  //2 - get data from params or local host, add to url
  useEffect(() => {
    // find in local storage or redirect to org selection page
    if (localStorage.org) {
      setCurrentOrg(localStorage.org);
    } else {
      navigate(`/select-org`);
    }
  }, []);

  //3 - fetch org
  useEffect(() => {
    if (currentOrg && currentOrg.length === 24) {
      selectOrg_Action(currentOrg);
      localStorage.setItem('org', currentOrg);
    }
  }, [currentOrg]);

  // todo - add optional field addOrgToURL
  // useEffect(() => {
  //   if (orgDetails?.org?._id?.length === 24 && addOrgToURL === true) {
  //     let params = new URL(document.location).searchParams;
  //     params.set('orgId', orgDetails?.org?._id);
  //     navigate('?' + params.toString());
  //   }
  // }, [orgDetails?.org?._id]);

  // 0 - if org data is in redux, continue
  if (orgDetails && orgDetails?.org?._id?.length === 24) {
    return children;
  }

  // 1 - if loading show spinner
  if (orgDetailsLoader === true && addOrgToURL === false) {
    return (
      <div className='mt-4 text-center'>
        <Spinner color='warning' />
      </div>
    );
  }
};

FetchOrgRoute.propTypes = {
  orgReducer: PropTypes.object.isRequired,
  selectOrg_Action: PropTypes.func.isRequired,
  addOrgToURL: PropTypes.bool.isRequired,
  children: PropTypes.object,
};

FetchOrgRoute.defaultProps = {
  addOrgToURL: false,
};

const mapStateToProps = (state) => ({
  orgReducer: state.orgReducer,
});

export default connect(mapStateToProps, { selectOrg_Action })(FetchOrgRoute);

