import React, { useContext, useState } from 'react';
import { Collapse, Nav } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import AppContext from 'context/Context';
import { usePostHog } from 'posthog-js/react';
import PropTypes from 'prop-types';

import NavbarVerticalMenuItem from 'blockscope/components/navbar/vertical/NavbarVerticalMenuItem';

const CollapseItems = ({ route, services, textColor }) => {
  const { pathname } = useLocation();

  const openCollapse = (childrens) => {
    const checkLink = (children) => {
      if (children.to === pathname) {
        return true;
      }
      return (
        Object.prototype.hasOwnProperty.call(children, 'children') &&
        children.children.some(checkLink)
      );
    };
    return childrens.some(checkLink);
  };

  const [open, setOpen] = useState(openCollapse(route.children));

  return (
    <Nav.Item as='li'>
      <Nav.Link
        onClick={() => {
          setOpen(!open);
        }}
        className={classNames('dropdown-indicator cursor-pointer', {
          'text-500': !route.active,
        })}
        aria-expanded={open}
        // {...route}
      >
        <NavbarVerticalMenuItem route={route} textColor={textColor} />
      </Nav.Link>
      <Collapse in={open}>
        <Nav className='flex-column nav' as='ul'>
          <NavbarVerticalMenu
            routes={route.children}
            services={services}
            textColor={textColor}
          />
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool,
  }).isRequired,
  services: PropTypes.arrayOf(PropTypes.string),
};

const NavbarVerticalMenu = ({ routes, services, textColor }) => {
  const posthog = usePostHog();
  const {
    config: { showBurgerMenu },
    setConfig,
  } = useContext(AppContext);

  const handleNavItemClick = (route) => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
    posthog.capture(route.posthogId || route.name);
  };
  return routes
    .filter((i) => {
      if (i.hide === true) {
        return false;
      }

      if (i.service && !services.includes(i.service)) {
        return false;
      }

      return true;
    })
    .map((route) => {
      if (!route.children) {
        return (
          <Nav.Item
            as='li'
            key={route.name}
            onClick={() => handleNavItemClick(route)}
          >
            <NavLink
              end={route.exact}
              to={route.to}
              state={{ open: route.to === '/authentication-modal' }}
              className={({ isActive }) =>
                isActive && route.to !== '#!' ? 'active nav-link' : 'nav-link'
              }
            >
              <NavbarVerticalMenuItem route={route} textColor={textColor} />
            </NavLink>
          </Nav.Item>
        );
      }
      return (
        <CollapseItems
          route={route}
          key={route.name}
          services={services}
          textColor={textColor}
        />
      );
    });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
    .isRequired,
  services: PropTypes.arrayOf(PropTypes.string),
};

export default NavbarVerticalMenu;

