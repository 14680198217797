export const solutionData = [
  {
    title: 'Web3 Businesses',
    description:
      'Meet compliance and shield against fraud with a flexible, streamlined solution.',
    link: '/solutions/studio',
    toolkits: ['Developers'],
    media: {
      icon: ['fa', 'store'],
      color: 'success',
      className: 'fs-2',
    }
  },
  {
    title: 'Government Agencies',
    description:
      'Safeguard consumers and maintain stability by tracing financial flows across blockchains.',
    link: '/solutions/defender',
    toolkits: ['Regulators', 'Investigators'],
    media: {
      icon: ['fa', 'building-columns'],
      color: 'danger',
      className: 'fs-2',
    }
  },
  {
    title: 'Financial Institutions',
    description:
      'Safeguard your investments and expand your offerings with secure, compliant crypto services.',
    link: '/solutions/defender',
    toolkits: ['Researchers', 'Analysts'],
    media: {
      icon: ['fa', 'coins'],
      color: 'warning',
      className: 'fs-2',
    }
  },
];
