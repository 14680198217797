import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getRandomColor, isIterableArray } from 'blockscope/helpers/utils';

import ExplanerV2 from 'blockscope/components/blockscopeCommon/Tooltip';
import Flex from 'blockscope/components/common/Flex';

const Avatar = ({
  size,
  rounded,
  src,
  name,
  emoji,
  className,
  mediaClass,
  isExact,
  icon,
  hideTooltip,
}) => {
  const classNames = ['avatar', `avatar-${size}`, className].join(' ');
  const mediaClasses = [
    rounded ? `rounded-${rounded}` : 'rounded',
    mediaClass,
  ].join(' ');

  const getAvatar = () => {
    if (src) {
      if (isIterableArray(src)) {
        return (
          <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
            <div className='w-50 border-right'>
              <img src={src[0]} alt='' />
            </div>
            <div className='w-50 d-flex flex-column'>
              <img src={src[1]} alt='' className='h-50 border-bottom' />
              <img src={src[2]} alt='' className='h-50' />
            </div>
          </div>
        );
      } else {
        return <img className={mediaClasses} src={src} alt='' />;
      }
    }

    if (name) {
      const randomColor = getRandomColor(name);
      return (
        <ExplanerV2 content={name} hideTooltip={hideTooltip}>
          <div
            className={`avatar-name ${mediaClasses}`}
            style={{
              backgroundColor: randomColor.color,
              color: randomColor.textColor,
            }}
          >
            <span> {isExact ? name : name.match(/\b\w/g).join('')}</span>
          </div>
        </ExplanerV2>
      );
    }

    if (icon) {
      return (
        <Flex className={`avatar-name ${mediaClasses}`}>
          <FontAwesomeIcon icon={icon} />
        </Flex>
      );
    }

    return (
      <div className={`avatar-emoji ${mediaClasses}`}>
        <span role='img' aria-label='Emoji'>
          {emoji}
        </span>
      </div>
    );
  };

  return <div className={classNames}>{getAvatar()}</div>;
};

export const AvatarGroup = ({ children, dense, className }) => {
  return (
    <div
      className={classNames(className, 'avatar-group', {
        'avatar-group-dense': dense,
      })}
    >
      {children}
    </div>
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']),
  rounded: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  name: PropTypes.string,
  emoji: PropTypes.string,
  className: PropTypes.string,
  mediaClass: PropTypes.string,
  isExact: PropTypes.bool,
  icon: PropTypes.string,
  hideTooltip: PropTypes.bool,
};

Avatar.defaultProps = {
  size: 'xl',
  rounded: 'circle',
  emoji: '😊',
  isExact: false,
  hideTooltip: false,
};

AvatarGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dense: PropTypes.bool,
};

export default Avatar;
