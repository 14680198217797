import React, { useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, Navigate, useSearchParams } from 'react-router-dom';

import ControlledInput from '../blockscopeCommon/ControlledInput';
import axios from 'axios';
import PropTypes from 'prop-types';

// actions
import {
  confirmEmail_Action,
  login_Action,
} from '../../../stateManagement/redux/actions/authActions';

import { axiosErrorToast } from 'blockscope/utils/toastHandler';

const LoginForm = ({
  hasLabel,

  // actions
  login_Action,
  confirmEmail_Action,

  //reducer
  isAuthenticated,
  confirmEmail,
  userInvites,
}) => {
  // State
  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const recaptchaRef = useRef();
  const [searchParams] = useSearchParams();

  // Handle Submission
  const onSubmit = (formData) => {
    if (confirmEmail !== undefined && confirmEmail === true) {
      confirmEmail_Action(
        formData.email,
        formData.password,
        searchParams.get('confirmId')
      );
    } else {
      login_Action(formData.email, formData.password, recaptchaValue);
    }

    reset({
      email: '',
      password: '',
    });
    recaptchaRef.current.reset();
  };
  if (isAuthenticated) {
    return <Navigate to='/dash' />;
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <ControlledInput
          control={control}
          paramName='email'
          placeholder='Email address'
          required='Email is required'
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Password</Form.Label>}
        <ControlledInput
          control={control}
          paramName='password'
          placeholder='Password'
          type='password'
          required='Password is required'
        />
      </Form.Group>

      <Row className='justify-content-between align-items-center'>
        <Col xs='auto'>
          {/* info: do not remove this, it keeps the next col aligned to right side */}
        </Col>
        <Col xs='auto'>
          <Link className='fs--1 mb-0' to={`/forgot-password`}>
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <ReCAPTCHA
        onChange={async () => {
          const captchaValue = recaptchaRef.current.getValue();

          if (captchaValue) {
            setRecaptchaVerified(true);
            setRecaptchaValue(captchaValue);
          } else {
            setRecaptchaVerified(false);
            axiosErrorToast('recaptcha not verified');
          }
        }}
        className='mt-3'
        style={{ display: 'flex', justifyContent: 'center' }}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        ref={recaptchaRef}
      />
      <Form.Group>
        <Button
          type='submit'
          className='mt-3 w-100 btn-primary'
          disabled={!recaptchaVerified}
        >
          Log in
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  hasLabel: PropTypes.bool,
  login_Action: PropTypes.func.isRequired,
  confirmEmail_Action: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  confirmEmail: PropTypes.bool,
};

LoginForm.defaultProps = {
  hasLabel: false,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  confirmEmail: state.authReducer.confirmEmail,
  userInvites: state.authReducer.userInvites,
});

export default connect(mapStateToProps, { login_Action, confirmEmail_Action })(
  LoginForm
);

