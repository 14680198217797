import { API_CALL_METHODS } from 'blockscope/components/documentation/developers/configs/code';
import { useDocumentStore } from 'blockscope/components/documentation/developers/state/documentStore';

function paramFunction(paramArray) {
  let paramStrings = [];
  let parameterCount = paramArray.length;
  paramArray.forEach((param, index) => {
    paramStrings.push(
      `${index + 1 === parameterCount ? '\n  ' : '  '}'${param.name}': ${
        param.type === 'string array' ? '[\n    ' : ''
      }'${param.value}'${param.type === 'string array' ? '\n  ]' : ''}`
    );
  });
  return `${paramStrings.map((string) => {
    return string;
  })}`;
}

export function createXmlCodeSnippet(
  callMethod = API_CALL_METHODS.GET,
  apiUrl = 'http://www.example.com/api/'
) {
  const apiKey = useDocumentStore((state) => state.apiKeyState);
  const parameterState = useDocumentStore((state) => state.parameterState);
  return `const data = JSON.stringify({
${paramFunction(parameterState)}
});

const xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener('readystatechange', function () {
  if (this.readyState === this.DONE) {
    console.log(this.responseText);
  }
});

xhr.open('${callMethod.value}', '${apiUrl}');
xhr.setRequestHeader('accept', 'application/json');
xhr.setRequestHeader('content-type', 'application/json');
xhr.setRequestHeader('X-API-KEY', '${apiKey}');

xhr.send(data);`;
}
