import React from 'react';
import PasswordResetForm_Bloc from '../PasswordResetForm';

const PasswordReset_Bloc = () => (
  <div className='text-center'>
    <h5>Reset new password</h5>
    <PasswordResetForm_Bloc />
  </div>
);

export default PasswordReset_Bloc;
