import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import className from 'classnames';
import PropTypes from 'prop-types';

const CardService = ({
  media,
  title,
  description,
  children,
  buttonText,
  customerText,
}) => (
  <Card className='card-span h-100'>
    <div className='card-span-img'>
      <FontAwesomeIcon
        icon={media.icon}
        className={className(
          { [`text-${media.color}`]: media.color },
          media.className
        )}
      />
    </div>
    <Card.Body className='pt-6 pb-4'>
      <h5 className='mb-2'>{title}</h5>
      <h6 className='mb-2 text-primary'>{customerText}</h6>

      {description && <p>{description}</p>}
      {children}
      <Link to={`/signup?from=${title}`}>
        {buttonText ? (
          <Button variant='falcon-default'>{buttonText}</Button>
        ) : null}
      </Link>
    </Card.Body>
  </Card>
);

CardService.propTypes = {
  media: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
    color: PropTypes.string.isRequired,
    className: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
  buttonText: PropTypes.string,
  customerText: PropTypes.string.isRequired,
};

export default CardService;
