import React, { useContext, useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import handleNavbarTransparency from 'blockscope/helpers/handleNavbarTransparency';
import classNames from 'classnames';
import { topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';

import blockscopeLogo from 'blockscope/assets/logo/blockscope-green.svg';

import NavbarTopDropDownMenus from 'blockscope/components/navbar/top/NavbarTopDropDownMenus';
import LandingRightSideNavItem from 'blockscope/components/pages/landing/LandingRightSideNavItem';

const NavbarStandard = () => {
  const {
    config: { isDark },
  } = useContext(AppContext);

  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, []);

  return (
    <Navbar
      variant={isDark ? 'light' : 'dark'}
      fixed='top'
      expand={topNavbarBreakpoint}
      className={classNames('navbar-standard navbar-theme', {
        'bg-100': !navbarCollapsed && isDark,
        'bg-dark': !navbarCollapsed && !isDark,
      })}
    >
      <Container>
        <Navbar.Brand className='text-white dark__text-white' as={Link} to='/'>
          <img className='me-2' src={blockscopeLogo} alt='Logo' width={38} />
          Blockscope
        </Navbar.Brand>

        <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />

        <Navbar.Collapse className='scrollbar'>
          <Nav>
            <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} />
          </Nav>
          <LandingRightSideNavItem />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;
