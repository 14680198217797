import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import { GENERAL_TOAST_ID } from 'blockscope/static/toastIds';

import { errorToast } from 'blockscope/utils/toastHandler';

const OnboardedRoute = ({
  // parent params
  children,

  // reducer
  orgState: { orgDetails, orgId },
}) => {
  const { orgStatus } = orgDetails.org;

  if (!orgStatus || orgStatus.hasAccess === false) {
    errorToast(
      'Access Revoked. Please contact support.',
      GENERAL_TOAST_ID.ERROR.ACCESS_REVOKED
    );
    return <Navigate to={`/dash?orgId=${orgId}`} />;
  }

  if (orgStatus.isSuspended === true) {
    errorToast(
      'Your organization has been suspended. Please contact support.',
      GENERAL_TOAST_ID.ERROR.SUSPENDED
    );
    return <Navigate to={`/all-blockscope-services`} />;
  }

  if (orgStatus && orgStatus.isBeta === true) {
    return children;
  } else {
    return children;
  }
};

OnboardedRoute.propTypes = {
  orgState: PropTypes.object.isRequired,
  children: PropTypes.object,
};

const mapStateToProps = (state) => ({
  orgState: state.orgReducer,
});

export default connect(mapStateToProps)(OnboardedRoute);
