import { io } from 'socket.io-client';

class SocketClient {
  /**
   * Socket Client
   * @class
   * @param {io} socket
   * */
  constructor() {
    this.socket = io(process.env.REACT_APP_BACKEND_URL, {
      withCredentials: true,
    });
  }
  updateToken(token) {
    this.socket.auth = { token };
    this.socket.disconnect().connect();
  }
  clearToken() {
    this.socket.auth = {};
    this.socket.disconnect().connect();
  }
}

const socketClient = new SocketClient();
export default socketClient;
