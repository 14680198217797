import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import Flex from 'blockscope/components/common/Flex';
import SoftBadge from 'blockscope/components/common/SoftBadge';

const NavbarVerticalMenuItem = ({ route, textColor }) => {
  return (
    <Flex alignItems='center' className={`${textColor}`}>
      {route.icon && (
        <span className='nav-link-icon'>
          <FontAwesomeIcon icon={route.icon} />
        </span>
      )}
      {route.heroIcon && (
        <span className='nav-link-icon'>
          <route.heroIcon style={{ marginLeft: '-2px', height: '20px' }} />
        </span>
      )}
      <span className='nav-link-text ps-1'>{route.name}</span>
      {route.badge && (
        <SoftBadge pill bg={route.badge.type} className='ms-2'>
          {route.badge.text}
        </SoftBadge>
      )}
    </Flex>
  );
};

// prop-types
const routeShape = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  heroIcon: PropTypes.node,
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape(routeShape).isRequired,
  textColor: PropTypes.string,
};

export default React.memo(NavbarVerticalMenuItem);
