import React from 'react';
import { Link } from 'react-router-dom';

// components
import Flex from '../../../../blockscope/components/common/Flex';
import LoginForm from '../../../../blockscope/components/authentication/LoginForm';

const SimpleLoginPage = () => (
  <>
    <Flex justifyContent='between' alignItems='center' className='mb-2'>
      <h5>Log in</h5>
      <p className='fs--1 text-600 mb-0'>
        or <Link to='/request-demo'>Contact us to get started</Link>
      </p>
    </Flex>
    <LoginForm />
  </>
);

export default SimpleLoginPage;

