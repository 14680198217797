import { API_CALL_METHODS } from 'blockscope/components/documentation/developers/configs/code';
import { useDocumentStore } from 'blockscope/components/documentation/developers/state/documentStore';

function paramFunction(paramArray) {
  let paramStrings = [];
  let parameterCount = paramArray.length;
  paramArray.forEach((param, index) => {
    paramStrings.push(
      `${index + 1 === parameterCount ? '\n    ' : '    '}${param.name}: ${
        param.type === 'string array' ? '[\n      ' : ''
      }'${param.value}'${param.type === 'string array' ? '\n    ]' : ''}`
    );
  });
  return `${paramStrings.map((string) => {
    return string;
  })}`;
}

export function createAxiosCodeSnippet(
  callMethod = API_CALL_METHODS.GET,
  apiUrl = 'http://www.example.com/api/'
) {
  const apiKey = useDocumentStore((state) => state.apiKeyState);
  const parameterState = useDocumentStore((state) => state.parameterState);
  return `import axios from 'axios';

const options = {
  method: '${callMethod.value}',
  url: '${apiUrl}',
  headers: {
    accept: 'application/json',
    'content-type': 'application/json'},
    'X-API-KEY': '${apiKey}'
  data: {
${paramFunction(parameterState)}
  }
};

axios
  .request(options)
  .then(function (response) {
    console.log(response.data);
  })
  .catch(function (error) {
    console.error(error);
  });`;
}
