import React from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Calendly = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const redirect = searchParams.get('redirect') || '';
  const prefill = {
    name: `${searchParams.get('firstName') || ''} ${
      searchParams.get('lastName') || ''
    }`,
    firstName: searchParams.get('firstName') || '',
    lastName: searchParams.get('lastName') || '',
    email: searchParams.get('email') || '',
  };

  useCalendlyEventListener({
    onEventScheduled: () => {
      if (redirect === 'register') {
        navigate(
          `/register?firstName=${prefill.firstName}&lastName=${prefill.lastName}&email=${prefill.email}`
        );
      }
    },
  });

  return (
    <div>
      <InlineWidget
        url='https://calendly.com/blockscope/30min'
        prefill={prefill}
        styles={{
          width: '100%',
          height: '100vh',
        }}
      />
    </div>
  );
};

Calendly.propTypes = {};

export default Calendly;

