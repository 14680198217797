import { applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reduxMain.js';

const initialState = {};

const middleware = [thunk];

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,

  // showing redux - helps debug
  devTools: composeWithDevTools(applyMiddleware(...middleware)),

  // info - when we want to disable redux from prod use the following
  // devTools:
  //   process.env.NODE_ENV === 'development' ? composeWithDevTools(applyMiddleware(...middleware)) : null,
});

export default store;
