import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { usePostHog } from 'posthog-js/react';
import PropTypes from 'prop-types';

const ProtectedRoute = ({
  children,
  auth: { isAuthenticated, loading, user },
}) => {
  const [loadingCount, setLoadingCount] = useState(1);
  const posthog = usePostHog();

  useEffect(() => {
    setLoadingCount(loadingCount + 1);
  }, [loading]);

  if (user) {
    posthog.identify(user._id, { email: user.email });
  }

  // 1 - if loading show spinner
  if (loading === true) {
    return (
      <div className='mt-4 text-center'>
        <Spinner color='warning' />
      </div>
    );
  }
  // 2 - is user data is available, reroute accordingly
  else if (isAuthenticated === true) {
    return children;
  }

  // 3 - wait for page to render atleast once before redirecting
  else {
    if (loadingCount >= 2) {
      return <Navigate to='/' replace></Navigate>;
    }
    return <div />;
  }
};

ProtectedRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

export default connect(mapStateToProps)(ProtectedRoute);
