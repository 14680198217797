import React from 'react';

import NavbarDropdown from 'blockscope/components/navbar/top/NavbarDropdown';
import NavbarDropdownCommunity from 'blockscope/components/navbar/top/dropdownOptions/NavbarDropdownCommunity';
import NavbarDropdownProducts from 'blockscope/components/navbar/top/dropdownOptions/NavbarDropdownProducts';
import NavbarDropdownSolutions from 'blockscope/components/navbar/top/dropdownOptions/NavbarDropdownSolutions';

const NavbarTopDropDownMenus = () => {
  return (
    <>
      <NavbarDropdown title='Industry'>
        <NavbarDropdownSolutions />
      </NavbarDropdown>
      <NavbarDropdown title='Tools' wide>
        <NavbarDropdownProducts />
      </NavbarDropdown>
      <NavbarDropdown title='Community'>
        <NavbarDropdownCommunity />
      </NavbarDropdown>
    </>
  );
};

export default NavbarTopDropDownMenus;

