import {
  SET_DELIVERY_TARGET_LOADER,
  GET_DELIVERY_TARGET_SETTINGS,
  SET_CREATE_DELIVERY_TARGET_LOADER,
  SET_DELETE_DELIVERY_TARGET_LOADER,
  SET_SINGLE_DELIVERY_TARGET_LOADER,
  GET_SINGLE_DELIVERY_TARGET_SETTING,
  LOGOUT,
  SWITCH_ORG,
} from '../types';

const initialState = {
  singleDeliveryTargetSummary: {},
  deliveryTargetsForOrg: [],
  deliveryTargetLoader: false,
  createDeliveryTargetLoader: false,
  singleDeliveryTargetLoader: false,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_DELIVERY_TARGET_LOADER:
      return {
        ...state,
        deliveryTargetLoader: payload.loadingStatus,
      };

    case GET_DELIVERY_TARGET_SETTINGS:
      return {
        ...state,
        deliveryTargetsForOrg: payload.targets,
      };

    case SET_CREATE_DELIVERY_TARGET_LOADER:
      return {
        ...state,
        createDeliveryTargetLoader: payload.loadingStatus,
      };

    case SET_DELETE_DELIVERY_TARGET_LOADER:
      return {
        ...state,
        deleteDeliveryTargetLoader: payload.loadingStatus,
      };

    case SET_SINGLE_DELIVERY_TARGET_LOADER:
      return {
        ...state,
        singleDeliveryTargetLoader: payload.loadingStatus,
      };

    case GET_SINGLE_DELIVERY_TARGET_SETTING:
      return {
        ...state,
        singleDeliveryTargetSummary: {
          target: payload.target,
          pipelines: payload.pipelines,
        },
      };
    case SWITCH_ORG:
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
