import axios from 'axios';
import { create } from 'zustand';

export const useDocumentStore = create((set, get) => ({
  apiKeyState: 'YOUR-API-KEY',
  setApiKeyState(key) {
    set({
      apiKeyState: key,
    });
  },
  parameterState: [],
  setParameterState(params) {
    set({
      parameterState: params,
    });
  },
  updateParameterState(id, newValue) {
    let newParameterState = [...get().parameterState];
    newParameterState[id].value = newValue;
    set({
      parameterState: newParameterState,
    });
  },
  apiLoadingState: 'standby',
  apiResponseState: {},
  async tryApiEndpoint(endPoint) {
    const data = {};
    const currentParams = [...get().parameterState];
    currentParams.forEach((param) => {
      if (param.type === 'string array') {
        data[param.name] = [param.value];
      } else {
        data[param.name] = param.value;
      }
    });
    set({
      apiLoadingState: 'loading',
    });
    const options = {
      method: 'POST',
      url: endPoint,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'X-API-KEY': get().apiKeyState,
      },
      validateStatus: () => {
        return true;
      },
      data: data,
    };
    await axios
      .request(options)
      .catch((error) => {
        set({
          apiLoadingState: 'ready',
        });
        set({
          apiResponseState: error,
        });
      })
      .then((response) => {
        set({
          apiLoadingState: 'ready',
        });
        set({
          apiResponseState: { ...response },
        });
      });
  },
  resetApiResponse() {
    set({
      apiLoadingState: 'standby',
    });
    set({
      apiResponseState: {},
    });
  },
}));
