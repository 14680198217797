import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Col, Nav, Navbar, Row } from 'react-bootstrap';

import { navbarBreakPoint } from 'config';
import AppContext from 'context/Context';
import PropTypes from 'prop-types';

import { SOLUTIONS } from 'blockscope/static/solutionConsts';

import { buildNavbarMenu } from './utils';
import { capitalize } from 'blockscope/helpers/utils';

import Flex from 'blockscope/components/common/Flex';
import Logo from 'blockscope/components/common/Logo';

import 'blockscope/components/navbar/vertical/NavbarVertical.css';
import NavbarVerticalMenu from 'blockscope/components/navbar/vertical/NavbarVerticalMenu';
import ScheduleDemoCard from 'blockscope/components/navbar/vertical/ScheduleDemoCard';
import ToggleButton from 'blockscope/components/navbar/vertical/ToggleButton';
import { blockscopeRoutes } from 'blockscope/components/navbar/vertical/blockscopeRoutes';

const solutionClass = {
  [SOLUTIONS.STUDIO]: 'navbar-studio',
  [SOLUTIONS.DEVKIT]: 'navbar-devkit',
  [SOLUTIONS.DEFENDER]: 'navbar-defender',
};
const NavbarLabel = ({ label, textColor, dividerColor }) => {
  return (
    <Nav.Item as='li'>
      <Row className='mt-3 mb-2 navbar-vertical-label-wrapper'>
        <Col
          xs='auto'
          className={`navbar-vertical-label navbar-vertical-label ${textColor}`}
        >
          {label}
        </Col>
        <Col className='ps-0'>
          <hr
            className='mb-0 navbar-vertical-divider'
            style={{ color: dividerColor }}
          ></hr>
        </Col>
      </Row>
    </Nav.Item>
  );
};
NavbarLabel.propTypes = {
  label: PropTypes.string,
  textColor: PropTypes.string,
  dividerColor: PropTypes.string,
};

const NavbarVertical = ({ services, solution = SOLUTIONS.STUDIO }) => {
  const {
    config: { showBurgerMenu, isNavbarVerticalCollapsed },
  } = useContext(AppContext);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    const newMenu = buildNavbarMenu(blockscopeRoutes, solution, services);

    setMenu(newMenu);
  }, [solution, services]);

  useEffect(() => {
    document
      .getElementsByTagName('html')[0]
      .classList.toggle('navbar-vertical-collapsed', isNavbarVerticalCollapsed);
  }, [isNavbarVerticalCollapsed]);
  return (
    <Navbar
      expand={navbarBreakPoint}
      className={`navbar-vertical navbar-vibrant ${solutionClass[solution]}`}
    >
      <Flex alignItems='center'>
        <ToggleButton />
        <Logo at='navbar-vertical' width={35} textClass='fs-2' />
      </Flex>
      <Navbar.Collapse in={showBurgerMenu}>
        <div className='navbar-vertical-content scrollbar'>
          <Nav className='flex-column' as='ul'>
            {menu
              // display remaining routes
              .map((route) => {
                if (
                  route.service &&
                  services.includes(route.service) === false
                ) {
                  return <></>;
                }
                if (!route.service) {
                  return (
                    <Fragment key={route.label}>
                      {!route.labelDisable && (
                        <NavbarLabel label={capitalize(route.label)} />
                      )}
                      <NavbarVerticalMenu
                        routes={route.children}
                        services={services}
                      />
                    </Fragment>
                  );
                }
              })}
          </Nav>
          <>
            <ScheduleDemoCard />
          </>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  solution: PropTypes.string,
  services: PropTypes.arrayOf(PropTypes.string),
};

export default NavbarVertical;

