import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { sendForgotPasswordEmail } from 'stateManagement/redux/actions/authActions';
import { useDispatch } from 'react-redux';

const ForgetPasswordForm_Bloc = () => {
  // State
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(sendForgotPasswordEmail(email));
    navigate('/login');
  };

  return (
    <Form className='mt-4' onSubmit={handleSubmit}>
      <Form.Group className='mb-3'>
        <Form.Control
          placeholder={'Email address'}
          value={email}
          name='email'
          onChange={({ target }) => setEmail(target.value)}
          type='email'
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        <Button className='w-100' type='submit' disabled={!email}>
          Send reset link
        </Button>
      </Form.Group>

      <Link className='fs--1 text-600' to='/contact-us'>
        I can't recover my account using this page
        <span className='d-inline-block ms-1'> &rarr;</span>
      </Link>
    </Form>
  );
};

ForgetPasswordForm_Bloc.propTypes = {
  layout: PropTypes.string,
};

ForgetPasswordForm_Bloc.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm_Bloc;
