import React from 'react';
import { Row } from 'react-bootstrap';

import PropTypes from 'prop-types';

function BounceSpinLoader({ numBreaks = 4, style, loadingText }) {
  return (
    <>
      {Array(numBreaks)
        .fill()
        .map((_, i) => (
          <br key={i} />
        ))}
      <Row
        className='d-flex justify-content-center pt-4'
        style={{ position: 'relative', ...style }}
      >
        {loadingText ? (
          <div className='text-center mb-3 text-800 fw-semi-bold'>
            {loadingText}
          </div>
        ) : null}

        <div className='bs-bounce-spin-loader'>
          <div className='cube-wrapper'>
            <div className='cube'>
              <div className='cube-faces'>
                <div className='cube-face shadow'></div>
                <div className='cube-face bottom'></div>
                <div className='cube-face top'></div>
                <div className='cube-face left'></div>
                <div className='cube-face right'></div>
                <div className='cube-face back'></div>
                <div className='cube-face front'></div>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
}

BounceSpinLoader.propTypes = {
  numBreaks: PropTypes.number,
  style: PropTypes.object,
  loadingText: PropTypes.string,
};

export default BounceSpinLoader;

