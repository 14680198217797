import React, { Fragment } from 'react';
import { FiCheck } from 'react-icons/fi';

const WhatWeDo = () => {
  return (
    <Fragment>
      <section className='pt-md-8 pb-md-8 bg-dark'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 text-center'>
              <h6 className='text-uppercase text-success fw-bold'>
                What we offer
              </h6>

              <h1 className='fw-bold text-white'>
                {/* Web3 data platform for storage, analytics & integrations. */}
                {/* {`Blockscope offers a large suite of on-chain data tools.`}\ */}
                {`Tools to understand web3 data in a useful way.`}
              </h1>

              <p className='fs-lg text-success mb-6'>
                {/* Blockchian tools that empowers users to capture, analyze, and
                understand web3 data in a useful way. */}
                {`Blockscope is an all-in-one Data Platform with incredibly easy-to-use data tools for individuals, developers and institutions.`}
              </p>
            </div>
          </div>

          {/* <Row className='g-3'>
            {[
              {
                title: 'Blockchain Watchtower',
                description:
                  'No-code tool to capture and understand any on-chain data.',
                img: img1,
                link: '/create-watchtower',
                service: ORG_SERVICES.WATCHTOWER,
              },
              {
                title: 'Data Delivery Pipeline',
                description:
                  'Send on-chain data to your database in real time. We manage the indexing infra!',
                img: img2,
                link: '/create-pipeline',
                service: ORG_SERVICES.PIPELINES,
              },
              {
                title: 'Webhook Pipeline',
                description:
                  'Trigger webhooks based on rules you set for on-chian activity.',
                img: img2,
                link: '/create-pipeline',
                service: ORG_SERVICES.PIPELINES,
              },
            ].map((report, index) => (
              <ReportsItem key={index} report={report} enabled={true} />
            ))}
          </Row> */}

          <div className={`row `}>
            <div className='col-12 col-md-6 col-lg-3 mb-4'>
              <h4 className='fw-bold text-white mb-5'>Monitoring</h4>

              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Rule-based notifications</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>
                  Alerts to Telegram, email & more
                </p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Pre-defined capture rules</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Custom capture rules</p>
              </div>
            </div>

            <div className='col-12 col-md-6 col-lg-3 mb-4'>
              <h4 className='fw-bold text-white mb-5'>Observability</h4>

              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Contract Usage</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>No-code Querying</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>
                  Real-time Decoded Data Streams
                </p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Address Labels & Tags</p>
              </div>
            </div>

            <div className='col-12 col-md-6 col-lg-3 mb-4'>
              <h4 className='fw-bold text-white mb-5'>Compliance</h4>

              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>OFAC Sanction List</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Blacklist Events</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Wallet Profiling (KYC)</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Transaction Decoder (KYT)</p>
              </div>
            </div>

            <div className='col-12 col-md-6 col-lg-3 mb-4'>
              <h4 className='fw-bold text-white mb-5'>Forensics</h4>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Token Flow</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Multi-chain Asset Tracing</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Address Clustering</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Investigations</p>
              </div>
            </div>

            {/* <div className='col-12 col-md-6 col-lg-2 mb-4'>
              <h4 className='fw-bold text-white mb-5'>Analytics</h4>

              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Popular Web3 Entities</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>User Profiling</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Project Dashboards</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Prebuilt Queries</p>
              </div>
            </div>

            <div className='col-12 col-md-6 col-lg-2 mb-4'>
              <h4 className='fw-bold text-white mb-5'>Data Indexing</h4>

              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Real-time data capture</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Historical Backfill</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>
                  On-chain action-based webhooks
                </p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Risk & Behavior APIs</p>
              </div>
            </div> */}
            {/* <div className='col-12 col-md-6 col-lg-3 mb-4'>
              <h4 className='fw-bold text-white mb-5'>Dev APIs & More</h4>

              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>All tools available via APIs</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Labels API</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Risk API</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>AWS, GCP, MongoDb Connectors</p>
              </div>
            </div> */}

            {/* <div className='col-12 col-md-6 col-lg-3 mb-4'>
              <h4 className='fw-bold text-white mb-5'>Collaborative</h4>

              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>Multi-user support</p>
              </div>
              <div className='d-flex'>
                <FiCheck />
                <p className='text-white mx-1'>
                  Role-based access and management
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default WhatWeDo;

