import { evmToTronAddress, tronToEvmAddress } from './tron/convert';
import { z } from 'zod';

import { CHAINS } from 'blockscope/static/supportedChains.js';

const evmAddressSchema = z
  .string()
  .trim()
  .toLowerCase()
  .refine((value) => /^0x[0-9a-f]{40}$/.test(value), {
    message:
      'Invalid EVM address. All EVM addresses are a hex string starting with 0x and 42 characters longs (including 0x).',
  });

const tronAddressSchema = z
  .string()
  .length(34, 'Tron address must be exactly 34 characters long.')
  .regex(
    /^T[1-9A-HJ-NP-Za-km-z]+$/,
    "Tron address must start with 'T' and be Base58-encoded."
  )
  .refine((address) => {
    try {
      tronToEvmAddress(address);
      return true;
    } catch (error) {
      return false;
    }
  }, 'Tron address must be a valid Base58-encoded string.');

const evmTransactionHashSchema = z
  .string()
  .trim()
  .toLowerCase()
  .refine((value) => /^0x[0-9a-f]{64}$/.test(value), {
    message:
      'Invalid EVM transaction hash. All ethereum transaction hashes are a hex string starting with 0x and 66 characters longs (including 0x).',
  });

const isValidAddress = (address, chain) => {
  // regex automatically coerces the input to a string so we need to safeguard if the input is not a string
  if (typeof address !== 'string') {
    return false;
  }

  if (chain === CHAINS.BITCOIN) {
    return /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/.test(address);
  }
  if (chain === CHAINS.TRON) {
    return (
      tronAddressSchema.safeParse(address).success ||
      evmAddressSchema.safeParse(address).success
    );
  }
  return evmAddressSchema.safeParse(address).success;
};

const isValidTransactionHash = (hash) => {
  return evmTransactionHashSchema.safeParse(hash).success;
};

const formatToEvmAddress = (address, fromChain) => {
  switch (fromChain) {
    case CHAINS.TRON:
      return tronAddressSchema.safeParse(address).success
        ? tronToEvmAddress(address).toLowerCase()
        : address.toLowerCase();
    default:
      return address.toLowerCase();
  }
};

const formatFromEvmAddress = (address, toChain) => {
  if (isValidAddress(address, CHAINS.ETHEREUM)) {
    switch (toChain) {
      case CHAINS.TRON:
        return !tronAddressSchema.safeParse(address).success
          ? evmToTronAddress(address)
          : address;
      default:
        return address.toLowerCase();
    }
  }
  return address;
};

export default {
  isValidAddress,
  evmAddressSchema,
  evmTransactionHashSchema,
  tronAddressSchema,
  isValidTransactionHash,
  formatToEvmAddress,
  formatFromEvmAddress,
};

