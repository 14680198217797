import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import Section from 'blockscope/components/common/Section';

import CardService from 'blockscope/components/pages/landing/components/subComponents/CardService';
import SectionHeader from 'blockscope/components/pages/landing/components/subComponents/SectionHeader';

const Services = ({ showSectionHeader }) => {
  const services = [
    {
      media: {
        icon: ['fas', 'tower-cell'],
        color: 'dark',
        className: 'fs-4',
      },
      title: 'Watchtower',
      description:
        'Blockscope Watchtower is a powerful tool that allows you to monitor and analyze on-chain activity in real-time.',
      productLink: '/products/watchtower',
    },
    {
      media: {
        icon: ['fas', 'shuffle'],
        color: 'success',
        className: 'fs-4',
      },
      title: 'Tracer',
      description:
        'Blockscope Tracer allows regulators and investigators to trace interactions and fund flows on-chain.',
      productLink: '/products/tracer',
    },
    {
      media: {
        icon: ['fas', 'shield-halved'],
        color: 'danger',
        className: 'fs-4',
      },
      title: 'Security Monitoring',
      description:
        'Blockscope Security Monitoring is a monitoring tool that allows you to track and analyze smart contract activity on your deployed contracts.',
      productLink: '/products/contract-guard',
    },
    {
      media: {
        icon: ['fas', 'lock-open'],
        color: 'primary',
        className: 'fs-4',
      },
      title: 'Transaction Decoder',
      description:
        'Blockscope Transaction Decoder is allows you to decode and analyze on-chain transactions and smart contract interactions.',
      productLink: '/products/transaction-decoder',
    },
    {
      media: {
        icon: ['fas', 'address-card'],
        color: 'warning',
        className: 'fs-4',
      },
      title: 'Wallet Profiler',
      description:
        'Blockscope Wallet Profiler allows you to analyze and profile on-chain wallets.',
      productLink: '/products/wallet-profiler',
    },
    {
      media: {
        icon: ['fas', 'chart-line'],
        color: 'info',
        className: 'fs-4',
      },
      title: 'Contract Usage',
      description:
        'Blockscope Contract Usage gathers analytics and visualizations centered around smart contracts.',
      productLink: '/products/contract-usage',
    },
    {
      media: {
        icon: ['fas', 'tag'],
        color: 'secondary',
        className: 'fs-4',
      },
      title: 'Labels',
      description:
        'A real-time curated list of labels that can be used to tag and categorize on-chain entities and smart contracts.',
    },
    {
      media: {
        icon: ['fas', 'user-secret'],
        color: 'dark',
        className: 'fs-4',
      },
      title: 'Investigator',
      description:
        'Blockscope Investigator is a central investigation tool allowing you to collect, investigate, and analyze on-chain data in one spot.',
      productLink: '/products/investigator',
    },
    {
      media: {
        icon: ['fas', 'arrow-right-arrow-left'],
        color: 'primary',
        className: 'fs-4',
      },
      title: 'Entity Interaction',
      description:
        'Blockscope Entity Interaction allows you to visualize interactions between on-chain entities and smart contracts.',
    },

    {
      media: {
        icon: ['fas', 'puzzle-piece'],
        color: 'info',
        className: 'fs-4',
      },
      title: 'Contract Analysis',
      description:
        'Blockscope Contract Analysis platform decodes contracts and provides a detailed analysis of the contract functionality',
    },
    {
      media: {
        icon: ['fas', 'diagram-project'],
        color: 'warning',
        className: 'fs-4',
      },
      title: 'Web3 Projects',
      description:
        'Repository of current Web3 projects and initiaives, including detailed explanations and analysis of the projects and their functionality.',
      productLink: '/products/web-directory',
    },
    {
      media: {
        icon: ['fas', 'terminal'],
        color: 'dark',
        className: 'fs-4',
      },
      title: 'Data APIs',
      description:
        'Programmatic access to on-chain data, including raw, enriched, and curated data streams.',
      productLink: '/products/api-services',
    },
    {
      media: {
        icon: ['fas', 'cubes'],
        color: 'primary',
        className: 'fs-4',
      },
      title: 'Block Breakdown',
      description:
        'Blockscope Block Breakdown allows you to analyze and visualize block data.',
      productLink: '/products/block-breakdown',
    },
    {
      media: {
        icon: ['fas', 'bars-staggered'],
        color: 'danger',
        className: 'fs-4',
      },
      title: 'Pipelines',
      description:
        'Blockscope Pipelines fulfils all Web3 indexing needs and provides powerful APIs to access the raw and advanced on-chain information.',
      productLink: '/products/pipelines',
    },
    {
      media: {
        icon: ['fas', 'plus'],
        color: 'dark',
        className: 'fs-4',
      },
      title: 'And More...',
      description:
        'If you have a specific need, we can reach out to us for a custom solution.',
    },
  ];

  return (
    <Section bg='light' className='text-center' id='servicesSection'>
      {showSectionHeader === true && (
        <SectionHeader
          title='Tools'
          subtitle={
            'Blockscope offers a wide range of solutions for all your crypto needs.'
          }
        />
      )}

      <Row className='mt-6'>
        {services.map((service) => (
          <Col
            lg={4}
            sm={12}
            className={'mt-6 mt-lg-0 mb-6'}
            key={service.title}
            id={service.title}
          >
            {service.productLink ? (
              <Link to={service.productLink}>
                <CardService {...service} />
              </Link>
            ) : (
              <CardService {...service} />
            )}
          </Col>
        ))}
      </Row>
    </Section>
  );
};

Services.defaultProps = {
  showSectionHeader: true,
  showFAQ: false,
};

Services.propTypes = {
  showSectionHeader: PropTypes.bool,
  showFAQ: PropTypes.bool,
};

export default Services;

