import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SET_AUTH_REDUCER_LOADING,
  SET_AUTH_REDUCER_LOADING_FALSE,
  SET_CONFIRM_EMAIL,
  UPDATE_USER_CONFIGS,
  USER_INVITES_LOADED,
  USER_LOADED,
} from '../types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  userSetupStage: null,
  loading: false,
  user: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    // fetch user info
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        userSetupStage: payload.user.profileSetupStage,
        loading: false,
        user: payload.user,
      };
    case SET_AUTH_REDUCER_LOADING:
      return { ...state, loading: true };
    case SET_AUTH_REDUCER_LOADING_FALSE:
      return { ...state, loading: false };
    case USER_INVITES_LOADED:
      return { ...state, userInvites: payload.invites };

    // login and register logic
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
      };

    // login, register and logout logic
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('org');
      return initialState;
    case SET_CONFIRM_EMAIL:
      return {
        ...state,
        confirmEmail: payload.confirmEmail,
      };
    case UPDATE_USER_CONFIGS: {
      return {
        ...state,
        user: {
          ...state.user,
          userConfigs: {
            ...state.user.userConfigs,
            ...payload,
          },
        },
      };
    }
    default:
      return state;
  }
}

