import React from 'react';

import * as Tooltip from '@radix-ui/react-tooltip';
import PropTypes from 'prop-types';

import 'blockscope/components/blockscopeCommon/styles/Tooltip.css';

const ExplanerV2 = ({
  children,
  content,
  align = 'center',
  hideTooltip = false,
}) => {
  if (hideTooltip) {
    return <>{children}</>;
  } else if (!content) {
    return <div>{children}</div>;
  } else {
    return (
      <Tooltip.Provider delayDuration={0}>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              className='TooltipContent'
              align={align}
              sideOffset={5}
            >
              <span
                style={{
                  fontSize: '12px',
                  position: 'relative',
                  top: '-1px',
                }}
              >
                {content}
              </span>
              <Tooltip.Arrow className='TooltipArrow' />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    );
  }
};

ExplanerV2.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  align: PropTypes.string,
  hideTooltip: PropTypes.bool,
};

export default ExplanerV2;

