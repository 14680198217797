import { SERVICE_CHAINS } from 'blockscope/static/supportedChains';

import { API_CALL_METHODS } from 'blockscope/components/documentation/developers/configs/code';

const FE_DOCUMENTATION_BROWSER_ROUTES = {
  DECODER: {
    TRANSACTION: '/docs/api/v2/decoder/transaction',
  },
  LABELS: {
    ADDRESSES: '/docs/api/v2/labels/address',
  },
  RISK: {
    ADDRESSES: '/docs/api/v2/risk/address',
  },
};

export const riskServiceDocs = {
  ADDRESS: {
    name: 'Get Address Risk',
    method: API_CALL_METHODS.POST,
    api_url: '/dev-api/v2/risk/address',
    path: FE_DOCUMENTATION_BROWSER_ROUTES.RISK.ADDRESSES,
    description: 'Get security labels and risk scores from addresses',
    chains: SERVICE_CHAINS.LABELS,
    inputs: [
      {
        id: 0,
        name: 'addresses',
        type: 'string array',
        value: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
        enum: null,
        required: true,
        description:
          'An array of wallet addresses that you wish to assess risk for',
        editable: true,
      },
      {
        id: 1,
        name: 'chain',
        type: 'string enum',
        value: 'ethereum',
        enum: SERVICE_CHAINS.LABELS,
        required: true,
        description: 'The chain that the wallets live on',
        editable: true,
      },
    ],
    responseSchema: [
      {
        name: 'addressLabels',
        type: 'object array',
        description: 'Array of wallet addresses queried for security labels',
        children: [
          {
            name: 'labels',
            type: 'object array',
            description: 'Array of security labels for a wallet address',
            children: [
              {
                name: 'label',
                type: 'string',
                description: 'Description of security label',
              },
              {
                name: 'type',
                type: 'string',
                description: 'Type of security label',
              },
              {
                name: 'riskScore',
                type: 'number',
                description: 'Risk denoted by this security label',
              },
            ],
          },
          {
            name: '_id',
            type: 'string',
            description:
              'The address of this wallet that labels were fetched for',
          },
          {
            name: 'weightedRiskScore',
            type: 'number',
            description:
              'Risk score evaluated by the number and severity of security labels',
          },
          {
            name: 'maxRiskScore',
            type: 'number',
            description:
              'Evaluated by the security labels carrying the highest risk score',
          },
        ],
      },
      {
        name: 'unFlaggedAddresses',
        type: 'string array',
        description:
          'Array of queried addresses that returned no security labels',
      },
    ],
  },
};
