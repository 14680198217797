import axios from 'axios';
import socketClient from 'stateManagement/socket/socketClient';

const setAuthTokenIntoRequestHeader = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    socketClient.updateToken('Bearer ' + token);
  } else {
    delete axios.defaults.headers.common['Authorization'];
    socketClient.clearToken();
  }
};

export default setAuthTokenIntoRequestHeader;
