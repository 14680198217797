import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

import PropTypes from 'prop-types';

const PageWithHelmet = ({
  title,
  description,
  // parent params
  children,
}) => {
  return (
    <Fragment>
      <Helmet
        titleTemplate='%s | Blockscope'
        defaultTitle='Blockscope'
        title={title}
      >
        <meta name='description' content={description} />
      </Helmet>
      {children}
    </Fragment>
  );
};

PageWithHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.object,
};

export default PageWithHelmet;

