export const bgWhiteIcons = [
  {
    icon: ['fab', 'twitter'],
    color: 'twitter',
    bg: 'white',
    href: 'https://twitter.com/BlockscopeInc',
  },
  {
    icon: ['fab', 'linkedin-in'],
    color: 'linkedin',
    bg: 'white',
    href: 'https://www.linkedin.com/company/blockscopeco',
  },
  {
    icon: ['fab', 'medium'],
    color: 'gray-700',
    bg: 'white',
    href: 'https://medium.com/@blockscope.co',
  },
];
export default [
  { icon: ['fab', 'twitter'], color: 'twitter', href: '#!' },
  { icon: ['fab', 'linkedin-in'], color: 'linkedin', href: '#!' },
  { icon: ['fab', 'medium-m'], color: 'gray-700', href: '#!' },
];
