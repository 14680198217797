import React from 'react';
import { Badge, Col, Nav, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import className from 'classnames';

import PropTypes from 'prop-types';

import Flex from 'blockscope/components/common/Flex';

import { solutionData } from 'blockscope/components/navbar/top/dropdownOptions/dropdownData/solutions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SolutionItem = ({ solution, enabled }) => {
  const { media } = solution;
  return (
    <Col lg={12} xxl={12}>
      <Flex
        alignItems='center'
        className='px-3 bg-light rounded-3 border position-relative'
      >
        <FontAwesomeIcon
        icon={media.icon}
        className={className(
          { [`text-${media.color}`]: media.color },
          media.className
        )}
      />
        <div className='ms-3 my-x1'>
          <h5 className='fs-0 fw-semi-bold mb-2'>
            {enabled ? (
              <Link
                to={solution.link}
                className='text-900 hover-primary stretched-link'
              >
                {solution.title}
              </Link>
            ) : (
              <Link to={'#'} className='text-900 hover-primary stretched-link'>
                {solution.title}
              </Link>
            )}
          </h5>
          <h6 className='mb-0 text-600'>{solution.description}</h6>

          {solution.toolkits.map((toolkit, index) => (
            <Badge bg='' className='badge-soft-dark me-2 mt-2' key={index}>
              {toolkit}
            </Badge>
          ))}
        </div>
      </Flex>
    </Col>
  );
};

SolutionItem.propTypes = {
  enabled: PropTypes.bool.isRequired,
  solution: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    toolkits: PropTypes.arrayOf(PropTypes.string).isRequired,
    media: PropTypes.shape({
      icon: PropTypes.arrayOf(PropTypes.string).isRequired,
      color: PropTypes.string.isRequired,
      className: PropTypes.string.isRequired,
    })
  }).isRequired,
};

const NavbarDropdownSolutions = () => {
  return (
    <>
      <Nav.Link as={'p'} className={'text-dark mb-0 fw-bold ms-2'} to={'/'}>
        {'Blockchain Data Solutions'}
      </Nav.Link>
      <Row className='g-3' style={{ minWidth: '400px' }}>
        {solutionData.map((solution, index) => (
          <SolutionItem key={index.title} solution={solution} enabled={true} />
        ))}
      </Row>
    </>
  );
};

export default NavbarDropdownSolutions;
