import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';

import image from 'blockscope/assets/img/icons/spot-illustrations/creating.png';

import FalconCloseButton from 'blockscope/components/common/FalconCloseButton';
import ContactUsPage from 'blockscope/components/pages/ContactUsPage';

const ScheduleDemoCard = () => {
  const [show, setShow] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setShowModal(!showModal);
  };

  return (
    show && (
      <>
        <div className='settings my-3'>
          <Card className='p-0 bg-white rounded-2 position-relative'>
            <div
              className='position-absolute'
              style={{ right: '3px', top: '3px' }}
            >
              <FalconCloseButton
                size='sm'
                noOutline
                onClick={() => setShow(false)}
              />
            </div>
            <Card.Body className='text-center'>
              <img src={image} alt='' width={50} />
              <p className='fs--2 mt-2'>
                Need help getting started?
                <br />
              </p>
              <div className='d-grid gap-2'>
                <Button
                  size='sm'
                  className='btn-success'
                  onClick={() => toggle()}
                >
                  Schedule Demo
                </Button>
              </div>
            </Card.Body>
          </Card>
        </div>
        <Modal show={showModal} onHide={() => toggle()} size='l'>
          <Card>
            <Card.Body className='p-4 p-sm-5'>
              <ContactUsPage initialMessage="I'd like to schedule a call." />
            </Card.Body>
          </Card>
        </Modal>
      </>
    )
  );
};

export default ScheduleDemoCard;
