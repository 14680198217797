import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import interfaces from 'blockscope-interfaces';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { z } from 'zod';

import { passwordResetSubmission } from 'stateManagement/redux/actions/authActions';

const ResetPasswordSchema = z
  .object({
    password: interfaces.dragon.user.passwordSchema,
    confirmPassword: interfaces.dragon.user.passwordSchema,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  });

const PasswordResetForm_Bloc = ({ hasLabel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const resetToken = searchParams.get('resettoken');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(ResetPasswordSchema),
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
  });

  // Handler
  const onSubmit = (formData) => {
    dispatch(passwordResetSubmission(formData.password, email, resetToken));
    navigate('/login');
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          name='password'
          type='password'
          {...register('password')}
        />
        {errors.password && (
          <Form.Text className='text-danger'>
            {errors.password.message}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          name='confirmPassword'
          type='password'
          {...register('confirmPassword')}
        />
        {errors.confirmPassword && (
          <Form.Text className='text-danger'>
            {errors.confirmPassword.message}
          </Form.Text>
        )}
      </Form.Group>

      <Button type='submit' className='w-100'>
        Set password
      </Button>
    </Form>
  );
};

PasswordResetForm_Bloc.propTypes = {
  hasLabel: PropTypes.bool,
};

export default PasswordResetForm_Bloc;
