import React, { Fragment, useRef, useState } from 'react';
import { Alert, Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';

import axios from 'axios';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { contactUs_Action } from 'stateManagement/redux/actions/otherActions';

import { errorToast } from 'blockscope/utils/toastHandler';

const SelectOrgPage = ({ contactUs_Action, initialMessage }) => {
  const [loading, setLoading] = useState(false);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const recaptchaRef = useRef();
  const ContactUsSchema = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    message: Yup.string().max(2500).required('Message is required'),
  });

  return (
    <Fragment>
      <h5>Contact Blockscope</h5>
      <hr></hr>
      <Formik
        initialValues={{
          name: '',
          email: '',
          message: initialMessage ? initialMessage : '',
        }}
        validationSchema={ContactUsSchema}
        onSubmit={async (values, { resetForm }) => {
          await contactUs_Action(
            values.name,
            values.email,
            values.message,
            setLoading
          );

          resetForm({
            name: '',
            email: '',
            message: '',
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert className='my-3' variant='danger'>
                <div className='alert-message'>{errors.submit}</div>
              </Alert>
            )}
            <Form.Label>Name</Form.Label>

            <InputGroup className='mb-3'>
              <FormControl
                size='md'
                placeholder=''
                aria-label='org name'
                aria-describedby='basic-addon2'
                name='name'
                value={values.name}
                isInvalid={Boolean(touched.name && errors.name)}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              {!!touched.name && (
                <Form.Control.Feedback type='invalid'>
                  {errors.name}
                </Form.Control.Feedback>
              )}
            </InputGroup>

            <Form.Group className='mb-3'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                size='md'
                type='email'
                name='email'
                placeholder=''
                value={values.email}
                isInvalid={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.email && (
                <Form.Control.Feedback type='invalid'>
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Message</Form.Label>
              <Form.Control
                size='md'
                as='textarea'
                name='message'
                rows='5'
                placeholder=''
                value={values.message}
                isInvalid={Boolean(touched.message && errors.message)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.message && (
                <Form.Control.Feedback type='invalid'>
                  {errors.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <ReCAPTCHA
              onChange={async () => {
                const captchaValue = recaptchaRef.current.getValue();
                const res = await axios.post(
                  '/api/v2/misc/recaptcha',
                  { captchaValue },
                  {
                    'content-type': 'application/json',
                  }
                );
                if (res?.data?.dataPayload?.verified === true) {
                  setRecaptchaVerified(true);
                } else {
                  setRecaptchaVerified(false);
                  errorToast('recaptcha not verified');
                }
              }}
              className='mt-3'
              style={{ display: 'flex', justifyContent: 'center' }}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              ref={recaptchaRef}
            />
            {recaptchaVerified && (
              <div className='text-center mt-3'>
                <Button
                  disabled={loading}
                  type='submit'
                  variant='primary'
                  size='md'
                >
                  {loading ? 'Sending...' : `Send`}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

SelectOrgPage.propTypes = {
  contactUs_Action: PropTypes.func.isRequired,
  initialMessage: PropTypes.string,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { contactUs_Action })(SelectOrgPage);

