import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';

import bg2 from 'blockscope/assets/landingBackgrounds/unsplash_bg2.jpg';
import bg3 from 'blockscope/assets/landingBackgrounds/unsplash_bg3.jpg';
import bg4 from 'blockscope/assets/landingBackgrounds/unsplash_bg4.jpg';
import bg5 from 'blockscope/assets/landingBackgrounds/unsplash_bg5.jpg';
import bg6 from 'blockscope/assets/landingBackgrounds/unsplash_bg6.jpg';

import YoutubeEmbededVideo from 'blockscope/components/blockscopeCommon/youtube/YoutubeEmbededVideo';
import Section from 'blockscope/components/common/Section';

const Hero = () => {
  const {
    config: { isRTL },
  } = useContext(AppContext);

  const bannerImgs = {
    0: bg2,
    1: bg3,
    2: bg4,
    3: bg5,
    4: bg6,
  };

  return (
    <Section
      className='py-0 overflow-hidden light'
      image={
        bannerImgs[Math.floor(Math.random() * Object.keys(bannerImgs).length)]
      }
      position='center bottom'
      overlay
    >
      <Row className='justify-content-center align-items-center pt-11 pt-xxl-11'>
        <Col md={11} lg={8} xl={5} className='pb-9 text-center text-xl-start'>
          <Button
            as={Link}
            // variant='outline-danger'
            variant='success'
            className='mb-4 fs--1 border-2 rounded-pill text-dark'
            to='/signup?from=demo'
          >
            {/* <span className='me-2' role='img' aria-label='Gift'>
              🖥️
            </span> */}
            Request Demo
          </Button>
          <h1 className='text-white fw-light'>
            {`Capture & Analyze`}
            <br></br>
            <span className='fw-bold'>
              <Typewriter
                words={[
                  'Logs',
                  'Transactions',
                  'Events',
                  'Blocks',
                  'traces',
                  'NFT Transfers',
                  'ERC-20 Events',
                  'Defi Txns',
                  'Security Alerts',
                  'Whale Activity',
                  'Popular Tokens',
                  'Any Wallet',
                  'Contract Usage',
                  'Mixer Activity',
                ]}
                loop={false}
                cursor={!isRTL ? true : false}
                cursorStyle='|'
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
            <br />
            in real time
          </h1>
          <p className='lead text-white opacity-75'>
            {`Easy-to-use data tools for Web3.`}
          </p>
          <Button
            as={Link}
            variant='outline-light'
            size='lg'
            className='border-2 rounded-pill mt-4 fs-0 py-2'
            to='/signup'
          >
            Start using Blockscope
            <FontAwesomeIcon icon='play' transform='shrink-6 down-1 right-5' />
          </Button>
        </Col>
        <Col>
          <YoutubeEmbededVideo embedId='5vIFhl0yuL4' />
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;
