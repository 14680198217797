import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Controller } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import ChainLogo from 'blockscope/components/blockscopeCommon/ChainLogo';

const ChainSelector = ({ chainOptions, chain, setChain = () => {} }) => {
  const [show, setShow] = useState(null);

  return (
    <Dropdown navbar={true} show={show} onToggle={() => setShow(!show)}>
      <Dropdown.Toggle
        bsPrefix='toggle'
        className='px-2 border'
        style={{
          // all: 'unset',
          backgroundColor: 'white',
          color: 'grey',
          cursor: 'pointer',
          height: 38,
          //
        }}
        size='sm'
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 30 }}>
            {chain ? <ChainLogo chain={chain} /> : <span>Chain</span>}
            {}
          </div>
          <FontAwesomeIcon icon={'caret-down'} className='fs-0' />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className='py-1'>
        {chainOptions.map((chain) => (
          <Dropdown.Item
            className='px-2'
            key={chain.value}
            onClick={() => setChain(chain.value)}
            active={chain === chain.value}
            style={{ display: 'flex', height: '40px', alignItems: 'center' }}
          >
            <div style={{ width: 25 }} className='text-center me-1'>
              <ChainLogo chain={chain.value} style={{ marginRight: '8px' }} />
            </div>
            <h6 style={{ margin: '0', lineHeight: '1.5' }}>{chain.label}</h6>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export const ControlledChainSelector = ({
  chainOptions,
  control,
  paramName = 'chain',
}) => {
  return (
    <Controller
      control={control}
      name={paramName}
      render={({ field }) => {
        return (
          <ChainSelector
            chainOptions={chainOptions}
            chain={field.value}
            setChain={(val) => {
              field.onChange(val);
            }}
          />
        );
      }}
    />
  );
};

ControlledChainSelector.propTypes = {
  chainOptions: PropTypes.array,
  control: PropTypes.object,
  paramName: PropTypes.string,
};

ChainSelector.propTypes = {
  chainOptions: PropTypes.array,
  chain: PropTypes.string,
  setChain: PropTypes.func,
  tiles: PropTypes.bool,
};

export default ChainSelector;

