import { API_CALL_METHODS } from 'blockscope/components/documentation/developers/configs/code';
import { useDocumentStore } from 'blockscope/components/documentation/developers/state/documentStore';

function paramFunction(paramArray) {
  let paramStrings = [];
  let parameterCount = paramArray.length;
  paramArray.forEach((param, index) => {
    paramStrings.push(
      `${index + 1 === parameterCount ? ' ' : ''}"${param.name}": ${
        param.type === 'string array' ? '[' : ''
      }"${param.value}"${param.type === 'string array' ? ']' : ''}`
    );
  });
  return `${paramStrings.map((string) => {
    return string;
  })}`;
}

export function createPythonCodeSnippet(
  callMethod = API_CALL_METHODS.GET,
  apiUrl = 'http://www.example.com/api/'
) {
  const apiKey = useDocumentStore((state) => state.apiKeyState);
  const parameterState = useDocumentStore((state) => state.parameterState);
  return `import requests

url = "${apiUrl}"

payload = { ${paramFunction(parameterState)} }
headers = {
    "accept": "application/json",
    "content-type": "application/json"
    "X-API-KEY": "${apiKey}"
}

response = requests.${callMethod.value.toLowerCase()}(url, json=payload, headers=headers)

print(response.text)`;
}
