export const WATCHTOWER_POSTHOG = {
  CREATE_PREBUILT: 'create-prebuilt-watchtower',
  CREATE_TEMPLATED: 'create-templated-watchtower',
  CREATE_CUSTOM: 'create-custom-watchtower',
  OPEN: 'open-watchtower',
  CLOSE: 'close-watchtower',
  START: 'start-watchtower',
  STOP: 'stop-watchtower',
  DELETE: 'delete-watchtower',
  EDIT: 'edit-watchtower',
  MAKE_PUBLIC: 'make-public-watchtower',
  MAKE_PRIVATE: 'make-private-watchtower',
  TABS: {
    DASHBOARD: 'watchtower-dashboard-tab',
    MY_WATCHTOWERS: 'watchtower-my-watchtowers-tab',
    SAVED_WALLETS: 'watchtower-saved-wallets-tab',
    SAVED_CONTRACTS: 'watchtower-saved-contracts-tab',
    RECORDS: 'watchtower-records-tab',
    BLOCKS: 'watchtower-blocks-tab',
    ANALYTICS: 'watchtower-analytics-tab',
    EMAIL_NOTIFICATIONS: 'watchtower-email-notifications-tab',
  },
};

export const PIPELINE_POSTHOG = {
  CREATE_PREBUILT: 'create-prebuilt-pipeline',
  CREATE_TEMPLATED: 'create-templated-pipeline',
  CREATE_CUSTOM: 'create-custom-pipeline',
  OPEN: 'open-pipeline',
  CLOSE: 'close-pipeline',
  START: 'start-pipeline',
  STOP: 'stop-pipeline',
  DELETE: 'delete-pipeline',
  TABS: {
    MY_PIPELINES: 'pipeline-my-pipelines-tab',
    DELIVERY_TARGETS: 'pipeline-delivery-targets-tab',
  },
};

export const INVESTIGATOR_POSTHOG = {
  CREATE: 'create-investigation',
  OPEN: 'open-investigation',
  DELETE: 'delete-investigation',
  UPDATE: 'update-investigation',
  DELETE_ITEM: 'delete-investigation-item',
  UPDATE_ITEM: 'update-investigation-item',
  ADD_ITEM: 'add-investigation-item',
  UPDATE_COMPONENTS: 'update-investigation-item-components',
};

export const DELIVERY_DOC_POSTHOG = {
  OPEN_DETAILS: 'open-delivery-doc-details',
};

export const TOKENFLOW_POSTHOG = {
  SEARCH: 'token-flow-search',
};
export const TRACER_POSTHOG = {
  SEARCH: 'tracer-search',
  DELETE: 'delete-tracer',
  SAVE: 'save-tracer',
};

export const TXN_DECODER_POSTHOG = {
  SEARCH: 'transaction-decoder-search',
  VIEW: 'transaction-decoder-view-tab',
  TAB: {
    ANALYTICS: 'transaction-decoder-analytics-tab',
    TRACE_CALL: 'transaction-decoder-trace-call-tab',
    GRAPH: 'transaction-decoder-trace-graph-tab',
    TOKEN_TRANSFERS: 'transaction-decoder-transfers-tab',
    LOGS: 'transaction-decoder-logs-tab',
    BALANCE_DIFFERENCES: 'transaction-decoder-balances-tab',
    ADDRESS_INTERACTION: 'transaction-decoder-address-interaction-tab',
  },
};

export const TXN_SIMULATOR_POSTHOG = {
  SEARCH: 'transaction-simulator-search',
  VIEW: 'transaction-simulator-view-tab',
  TAB: {
    ANALYTICS: 'transaction-simulator-analytics-tab',
    TRACE_CALL: 'transaction-simulator-trace-call-tab',
    GRAPH: 'transaction-simulator-trace-graph-tab',
    TOKEN_TRANSFERS: 'transaction-simulator-transfers-tab',
    LOGS: 'transaction-simulator-logs-tab',
    BALANCE_DIFFERENCES: 'transaction-simulator-balances-tab',
    ADDRESS_INTERACTION: 'transaction-simulator-address-interaction-tab',
  },
};

export const ABI_GUESSER_POSTHOG = {
  SEARCH: 'evm-abi-guesser-search',
};

export const BLOCK_BREAKDOWN_POSTHOG = {
  SEARCH: 'block-breakdown-search',
  PREVIOUS: 'block-breakdown-previous',
  NEXT: 'block-breakdown-next',
  TAB: {
    OVERVIEW: 'block-breakdown-overview-tab',
    ANALYTICS: 'block-breakdown-analytics-tab',
    TRANSACTIONS: 'block-breakdown-transactions-tab',
    TRACES: 'block-breakdown-traces-tab',
    LOGS: 'block-breakdown-logs-tab',
    NFTS: 'block-breakdown-nfts-tab',
  },
};

export const CONTRACT_USAGE_POSTHOG = {
  SEARCH: 'contract-usage-search',
};

export const CONTRACT_BREAKDOWN_POSTHOG = {
  SEARCH: 'contract-breakdown-search',
  TAB: {
    ABI_ANALYTICS: 'contract-breakdown-abi-analytics-tab',
    SOURCE_CODE: 'contract-breakdown-source-code-tab',
  },
};

export const SIDEBAR_POSTHOG = {
  NAV: 'sidebar-service-navigation',
};

export const ALL_SERVICES_POSTHOG = {
  SERVICES: 'all-services-selection',
};

export const WALLET_ACTIVITY_POSTHOG = {
  SEARCH: 'wallet-activity-search',
  VIEW: 'wallet-activity-view-tab',
  TAB: {
    CURRENT_HOLDINGS: 'wallet-activity-assets-tab',
    RECENT_ACTIVITY: 'wallet-activity-recent-activity-tab',
    TRANSACTIONS: 'wallet-activity-transactions-tab',
    INTERNAL_TRANSACTIONS: 'wallet-activity-traces-tab',
    LABELS: 'wallet-activity-labels-tab',
    TOKEN_TRANSFERS: 'wallet-activity-token-tranasfers-tab',
    NFT_TRANSFERS: 'wallet-activity-nft-tranasfers-tab',
  },
};

export const MEMO_POSTHOG = {
  CREATE: 'create-memo',
  DELETE: 'delete-memo',
  VIEW_TAB: 'memo-view-tab',
  TAB: {
    WALLETS: 'memo-wallets-tab',
    CONTRACTS: 'memo-contracts-tab',
    CONTRACT_ABIS: 'memo-contract-abis-tab',
    TRANSACTIONS: 'memo-transactions-tab',
    EVENTS: 'memo-events-tab',
    FUNCTIONS: 'memo-functions-tab',
  },
};

export const WEB3_POSTHOG = {
  OPEN: 'open-web3-entity',
  VIEW_PROTOCOL: 'web3-entity-view-protocol',
};

export const LABELS_POSTHOG = {
  OPEN: 'open-label-page',
  SELECT_CHAIN: 'label-page-select-chain',
};

export const ORACLE_POSTHOG = {
  ADDRESSES: {
    ADD: 'oracle-add-address',
    REMOVE: 'oracle-remove-address',
  },
  ALERT: {
    OPEN: 'oracle-open-alert-instance',
    UPDATE_ASSIGNEE: 'oracle-update-assignee',
    UPDATE_STATUS: 'oracle-update-alert-status',
    ADD_NOTE: 'oracle-add-note',
    UPDATE_NOTE: 'oracle-update-note',
  },
  RULES: {
    CREATE: 'oracle-create-rule',
    DELETE: 'oracle-delete-rule',
    START: 'oracle-start-rule',
    STOP: 'oracle-stop-rule',
  },
};
export const ENTITY_INTERACTION_POSTHOG = {
  SEARCH: 'entity-interaction-search',
  TAB: {
    ANALYTICS: 'entity-interaction-analytics-tab',
    ALL_INTERACTIONS: 'entity-interaction-all-interactions-tab',
  },
};

export const OPEN_PAGE_POSTHOG = {
  EXTERNAL: 'open-page-external',

  LANDING: 'open-page-landing',
  REGISTER: 'open-page-register',
  LOGIN: 'open-page-login',
  ORG: { SELECT: 'open-page-select-org', SETTINGS: 'open-page-org-settings' },
  USER_SETTINGS: 'open-page-user-settings',
  DASHBOARD: 'open-page-dashboard',
  ALL_SERVICES: 'open-page-all-services',
  ADMIN_CONTROL: 'open-page-admin-control',
  BLOG: {
    ALL: 'open-page-all-blogs',
    SINGLE: 'open-page-single-blog',
  },
  WATCHTOWER: {
    CREATE: 'open-page-create-watchtower',
    MAIN: 'open-page-watchtowers',
    DETAILS: 'open-page-watchtower-details',
    PUBLIC: {
      ALL: 'open-page-all-public-watchtowers',
      DETAILS: 'open-page-public-watchtower-details',
    },
  },
  PIPELINE: {
    CREATE: 'open-page-create-pipeline',
    ALL: 'open-page-all-pipelines',
    DETAILS: 'open-page-pipeline-details',
    DELIVERY_SETTINGS: 'open-page-deliver-settings',
    DELIVERY_TARGET: 'open-page-deliver-target',
  },
  SAVED_CONTRACTS: {
    ALL: 'open-page-all-saved-contracts',
    DETAILS: 'open-page-saved-contract-details',
  },
  SAVED_WALLETS: {
    ALL: 'open-page-all-saved-wallets',
    DETAILS: 'open-page-saved-wallet-details',
  },
  SECURITY_MONITORING: {
    RULES: 'open-page-risk-rules',
    MONITORED_ADDRESSES: 'open-page-monitored-address',
    ALERTS: 'open-page-all-alerts',
    ALERT_INSTANCE: 'open-page-alert-details',
    DASHBOARD: 'open-page-oracle-dashboard',
  },
  MAIN_SEARCH: 'open-page-main-search',
  STREAMS: {
    BLOCKS: 'open-page-block-stream',
    EVENTS: 'open-page-event-stream',
    TRANSACTIONS: 'open-page-transaction-stream',
    TRACES: 'open-page-trace-stream',
  },
  BLOCK_BREAKDOWN: 'open-page-block-breakdown',
  TRANSACTION_DECODER: 'open-page-transaction-decoder',
  TRANSACTION_SIMULATOR: 'open-page-transaction-simulator',
  WALLET_PROFILE: 'open-page-wallet-profile',
  WEB3_PROJECTS: {
    DIRECTORY: 'open-page-project-directory',
    PROJECT: 'open-page-project',
    CHAIN: 'open-page-chain',
  },
  WEB3_HACKS: {
    DIRECTORY: 'open-page-hack-directory',
    SINGLE_HACK: 'open-page-single-hack',
  },
  LABELS: {
    ALL: 'open-page-all-labels',
    ADDRESSES: 'open-page-label-addresses',
  },
  TOKENFLOW: 'open-page-tokenflow',
  CONTRACT_USAGE: 'open-page-contract-usage',
  INVESTIGATOR: {
    CREATE: 'open-page-create-investigation',
    ALL: 'open-page-all-investigations',
    SAVED: 'open-page-investigation',
  },
  TRACER: {
    CREATE: 'open-page-new-tracer',
    ALL: 'open-page-all-tracers',
    SAVED: 'open-page-saved-tracer',
  },
  EVM_EVENTS_DIRECTORY: 'open-page-evm-events-directory',
  EVM_FUNCTIONS_DIRECTORY: 'open-page-evm-functions-directory',
  MEMOS: 'open-page-memos',
  DECODER_API_DOCS: 'open-page-decoder-api-docs',
  LABELS_API_DOCS: 'open-page-labels-api-docs',
  RISK_API_DOCS: 'open-page-risk-api-docs',
  CONTRACT_BREAKDOWN: 'open-page-contract-breakdown',
  ENTITY_INTERACTION: 'open-page-entity-interaction',
};

