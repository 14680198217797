import React from 'react';

import PropTypes from 'prop-types';

const TallyMailIllustration = ({ className, style = {} }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 135 87'
    className={className}
    style={style}
  >
    <g>
      <path
        className='st0'
        fill='#253D6C'
        d='M7.16,82.42c0.43,0.62,0.75,1.58,1.33,1.79c1.35,0.48,0.79,1.5,0.98,2.31c-1.9,0-3.8,0-5.7,0
		c0.36-1.22,0.71-2.44,1.07-3.66C5.49,82.11,6.22,81.69,7.16,82.42z'
      />
      <path
        className='st1'
        fill='#263962'
        d='M19.82,86.52c0.2-1.21,0.4-2.42,0.6-3.63c0.64-0.16,1.27-0.33,1.91-0.49c0.53,0.53,1.02,1.09,1.59,1.57
		c0.87,0.72,2.57,0.74,2.12,2.56C23.97,86.52,21.89,86.52,19.82,86.52z'
      />
      <path
        className='st2'
        fill='#FCEBDB'
        d='M38.44,35.6c1.48-2.27,1.48-2.5-0.07-4.08c0.57-0.93,1.88-1.59,1.14-3.01c-0.01-1.06-0.02-2.13-0.04-3.19
		c0.07-0.63,0.14-1.26,0.22-2.01c2.35,0,4.48,0.01,6.61,0c2.22-0.02,4.45-0.06,6.67-0.09c0.67-0.13,1.34-0.27,2.01-0.4
		c1.42,0.14,2.84,0.29,4.26,0.43c6.11,0.03,12.22,0.05,18.33,0.08c1.12,0.01,2.33-0.24,3.33,0.1c1.32,0.45,2.23,0.04,3.21-0.64
		c0.3,0,0.6,0,0.9,0c0.8,0.17,1.6,0.48,2.41,0.49c4.05,0.07,8.11,0.06,12.16,0.08c0.3,0.32,0.68,0.95,0.89,0.9
		c0.44-0.1,0.79-0.57,1.18-0.89c0.69,0,1.38,0,2.07,0.01c0.44,3.05-2.85,0.78-3.44,2.5c0.39,0.61,0.83,1.29,1.22,1.91
		c0.72-0.06,1.35-0.12,2.21-0.19c0,1.81-0.06,3.42,0.02,5.02c0.06,1.05,0.33,2.09,0.51,3.13c0,0.34,0,0.69,0,1.03
		c-0.18,2.07-0.35,4.14-0.53,6.21c-0.17,0.19-0.31,0.42-0.52,0.55c-8.17,4.93-16.33,9.88-24.54,14.76
		c-1.22,0.72-2.61,1.15-3.92,1.71c-2.1-1.2-4.19-1.19-6.25,0.11c-2.15-1.19-4.33-2.32-6.43-3.57c-6.53-3.9-13.02-7.85-19.53-11.78
		c-0.18-0.22-0.36-0.45-0.54-0.67l-0.02,0.06c0-4.01,0-8.01,0-12.02c-0.71,3.31-1.07,6.63-1.5,9.94c-0.05,0.36-0.42,0.68-0.65,1.02
		c-0.17-1.82-0.33-3.63-0.49-5.45C39.29,36.87,39.67,35.87,38.44,35.6z M59.7,42.97c-1.81,0.2-3.72-0.31-5.44,0.67
		c2.59,0.25,5.18,0.79,7.68-0.46c-0.23-0.07-0.46-0.14-0.69-0.21c0-0.5,0-1.01,0-1.51c7.23,0,14.47,0,21.7-0.02
		c0.75,0,1.51-0.15,2.26-0.24c0.04-0.01,0.09-0.12,0.1-0.2c0.02-0.08,0-0.17,0-0.26c-3.03-0.66-24.94-0.46-26.65,0.34
		c0.44,0.16,0.74,0.27,1.03,0.38C59.7,41.97,59.7,42.47,59.7,42.97z M59.7,30.04c-0.17,0.34-0.34,0.69-0.5,1.03
		c0,0.36-0.01,0.72-0.01,1.07c-1.84,0.21-3.68,0.43-5.51,0.64c0.26,0.65,0.47,0.84,0.68,0.84c11.51,0.02,23.02,0.03,34.53,0.03
		c0.33,0,0.66-0.12,0.98-0.21c0.06-0.01,0.11-0.11,0.13-0.18c0.03-0.08,0.02-0.17,0.04-0.41c-1.64-0.24-3.29-0.48-4.95-0.72
		c-0.13-0.8,0.45-1.95-1.03-2.09c-0.01-0.16-0.02-0.33-0.02-0.49c-0.47-0.35-0.94-1.01-1.41-1.01c-7.15-0.07-14.31-0.03-21.46-0.06
		C60.01,28.48,59.62,28.98,59.7,30.04z M79.64,26.26c0.03-0.2,0.06-0.4,0.1-0.6c-0.72-0.09-1.44-0.25-2.16-0.26
		c-5.93-0.02-11.86,0.04-17.79-0.04c-1.73-0.02-3.45-0.4-5.55-0.67c-0.17,0.2-0.65,0.72-1.4,1.57
		C62.06,26.26,70.85,26.26,79.64,26.26z M90,43.84c0.01-0.28,0.01-0.56,0.02-0.84c-2.88,0-5.77,0-8.65,0
		c-2.99,0-5.99,0.15-8.97-0.01c-4.1-0.22-7.19-0.06-8.39,0.85C72.76,43.84,81.38,43.84,90,43.84z M98.79,26.2
		c-2.91-1.11-14.99-0.93-17.04,0C87.77,26.2,93.28,26.2,98.79,26.2z M72.92,46.31c-2.39-0.96-12.81-0.86-14.49,0.04
		C63.26,46.69,68.09,46.61,72.92,46.31z M85.86,46.38c-2.07-1.08-9.88-1-11.62,0C78.28,46.38,82.07,46.38,85.86,46.38z M41.94,25.52
		c-0.06-0.14-0.08-0.35-0.18-0.42c-0.09-0.06-0.28,0.05-0.43,0.08c0.07,0.19,0.14,0.37,0.22,0.56
		C41.67,25.67,41.81,25.6,41.94,25.52z M51.77,25.43c-0.07-0.13-0.13-0.34-0.23-0.37c-0.12-0.04-0.28,0.09-0.43,0.15
		c0.11,0.17,0.21,0.34,0.32,0.52C51.55,25.62,51.66,25.53,51.77,25.43z M43.47,25.91c-0.09-0.12-0.16-0.32-0.27-0.34
		c-0.12-0.02-0.28,0.11-0.42,0.18c0.13,0.16,0.26,0.33,0.39,0.49C43.27,26.13,43.37,26.02,43.47,25.91z M41.42,29.15
		c-0.06-0.14-0.08-0.35-0.18-0.42c-0.09-0.06-0.28,0.05-0.43,0.08c0.07,0.19,0.14,0.37,0.22,0.56
		C41.16,29.3,41.29,29.22,41.42,29.15z'
      />
      <path
        className='st3'
        fill='#F69848'
        d='M74.76,60.02c1.31-0.56,2.7-0.99,3.92-1.71c8.2-4.88,16.37-9.83,24.54-14.76c0.21-0.13,0.35-0.36,0.52-0.55
		c0.21-0.08,0.42-0.16,0.64-0.24c0.11-0.27,0.22-0.55,0.32-0.82c0.38-0.15,0.75-0.31,1.13-0.46c1.98-0.14,3.42-1.23,4.69-2.63
		c0.19,1.03,0.38,2.06,0.57,3.1c-0.05,5-0.1,10.01-0.15,15.01c-2.06-0.41-3.64-1.61-5.01-3.13c-0.55-0.29-1.1-0.83-1.64-0.82
		c-1.62,0.02-3.25,0.23-4.86,0.43c-0.55,0.07-1.08,0.31-2.23,0.65c1.4,0.71,2.26,1.15,2.62,1.33c0.75,2.67,1.4,4.97,2.03,7.22
		c2.54,0,4.78,0,7.01,0c0.5,0.34,1,0.69,1.5,1.03c-0.42,2.49-0.87,4.97-1.24,7.47c-0.6,4.15-3,7.71-3.81,11.79
		c-0.03,0.13-0.26,0.21-0.4,0.32c-0.07-1.16-0.49-1.96-1.56-2.7c-3.51-2.41-6.86-5.05-10.32-7.54
		C86.95,68.64,80.85,64.34,74.76,60.02z'
      />
      <path
        className='st4'
        fill='#2F498C'
        d='M22.33,82.39c-0.64,0.16-1.27,0.33-1.91,0.49c-1.82-3.86-3.86-7.63-5.38-11.6c-0.8-2.09-0.7-4.53-0.95-6.82
		c-0.1-0.89-0.02-1.8-0.28-2.73c-4.33,6.26-5.24,13.56-6.66,20.69c-0.94-0.73-1.66-0.32-2.32,0.44c-0.06-1.11,0.05-2.27-0.2-3.33
		c-1.17-5-0.88-9.85,1.16-14.58c0.29-0.68,0.46-1.48,0.44-2.22C5.99,55.85,5.68,48.97,5.4,42.1c0.84,1.19,1.68,2.38,2.54,3.55
		c0.61,0.83,1.27,1.64,2.23,2.87c0.33-1.98,0.45-3.09-0.62-4.36c-0.91-1.08-1.13-2.74-1.66-4.14c0.19-0.07,0.38-0.14,0.57-0.21
		c1.14,0.2,2.26,0.51,3.41,0.58c1.94,0.12,3.89,0.08,5.83,0.11c0.1,0.39,0.21,0.78,0.31,1.17c-1.67,0.41-1.24,1.76-1.32,2.9
		c-0.03,0.42-0.08,0.87-0.25,1.24c-1.08,2.37-1.92,4.76-1.85,7.46c0.04,1.41-0.44,2.84-0.74,4.25c-0.25,1.16-0.66,2.32,1,3.15
		c1.85-3.4,3.64-6.68,5.36-9.84c0.19-0.03,0.43-0.06,0.67-0.09c-0.06,2.95,0.17,5.94-0.26,8.83c-0.79,5.32-0.67,10.54,0.54,15.77
		C21.7,77.65,21.95,80.04,22.33,82.39z'
      />
      <path
        className='st4'
        fill='#2F498C'
        d='M132,85.79c-0.27,0.02-0.55,0.04-0.82,0.06c-0.48-0.12-0.95-0.33-1.43-0.35c-3.92-0.15-6.12-2.3-6.33-6.23
		c0.34-0.39,0.8-0.74,0.94-1.19c0.06-0.18-0.61-0.58-0.95-0.88c0-0.34-0.01-0.68-0.01-1.02c0-1.38,0.04-2.76-0.04-4.14
		c-0.02-0.33-0.36-0.82-0.65-0.93c-0.32-0.11-1.03,0.04-1.12,0.27c-0.5,1.22-0.87,2.5-1.28,3.75c-0.35,0.69-0.7,1.37-1.05,2.06
		c-0.35,1.03-0.71,2.06-1.06,3.09c-0.57-0.72-1.07-0.6-1.68,0.01c-1.08,1.09-2.24,2.11-3.36,3.16c-1.06-0.2-2.12-0.39-3.18-0.59
		c0.59-1.06,1.28-2.09,1.76-3.2c0.33-0.76,0.39-1.64,0.55-2.48c0.77-4.08,1.53-8.16,2.31-12.24c0.15-0.8,0.41-1.58,0.61-2.37
		c2.39-0.11,4.77-0.21,7.16-0.32c1.38,1.87,2.77,3.75,4.15,5.62c1.49,2.12,2.97,4.25,4.46,6.37c1.63,2.55,2.6,5.35,2.46,8.36
		C133.39,83.69,132.51,84.73,132,85.79z'
      />
      <path
        className='st5'
        fill='#AACFA1'
        d='M52.98,23.22c-2.22,0.03-4.45,0.07-6.67,0.09c-2.13,0.01-4.26,0-6.61,0c-0.08,0.74-0.15,1.38-0.22,2.01
		c-0.15,1.07-0.3,2.15-0.44,3.22c-0.87,1.15-1.89,1.33-3.07,0.48c-1.46-1.99-3.44-3.69-3.72-6.74c1.92,0,3.62-0.01,5.32,0
		c1.33,0.01,1.63-0.63,1.22-1.76c-2.21-0.47-4.38-0.84-6.5-1.43c-0.71-0.2-1.64-0.86-1.84-1.5c-1.18-3.82,0.49-8.42,5.46-8.86
		c4.17-0.37,5.27,2.41,6.71,5.19c0.67,1.29,1.53,2.49,2.52,4.08c1.47-2.98-0.76-4.64-1.31-6.85c1.15-0.29,2.13-0.42,3.31,0.31
		c1.63,1.02,3.49,1.65,5.17,2.59c0.87,0.48,2.03,1.04,1.31,2.46c-0.21,0.61-0.57,1.21-0.6,1.82C52.93,19.97,52.98,21.6,52.98,23.22z
		'
      />
      <path
        className='st3'
        fill='#F69848'
        d='M17.71,40.5c-1.94-0.03-3.89,0.01-5.83-0.11c-1.14-0.07-2.27-0.38-3.41-0.58c0.43-2.58,0.91-5.16,1.26-7.76
		c0.11-0.85-0.1-1.74-0.17-2.61c-0.14,0.02-0.27,0.04-0.41,0.05c-0.21,0.53-0.41,1.06-0.62,1.6c-0.06-1.19-0.11-2.38-0.17-3.57
		c0-0.93,0-1.86,0-2.69c-1.29-0.95-3.2-1.07-3.1-3.15c0.52-1.89,1.72-3.09,3.62-3.59c1.16,0,2.32,0,3.5,0
		c0.15,1.24,0.26,2.07,0.36,2.91c2.33,0.48,4.51,0.94,7.12,1.48c-0.44-1.8-0.74-3.04-1.04-4.27c2.23,0.7,4.13,1.81,5.13,4.07
		c-0.24,0.07-0.63,0.07-0.7,0.23c-1.28,2.74-2.83,5.41-2.38,8.62c-0.25,2.86-0.51,5.72-0.76,8.59
		C19.32,39.98,18.51,40.24,17.71,40.5z'
      />
      <path
        className='st5'
        fill='#AACFA1'
        d='M99.59,23.37c-4.06-0.02-8.11-0.01-12.16-0.08c-0.8-0.01-1.6-0.32-2.41-0.49c-0.46-2.58,0.97-4.38,2.71-5.87
		c0.78-0.66,2.06-0.73,3.17-0.9c2.38-2.38,5.18-4.02,8.74-4.09c0.36-0.01,0.72,0.34,1.08,0.52c-0.95,1.75-1.9,3.5-2.84,5.25
		c0.23,0.16,0.46,0.31,0.69,0.47c1.47-0.83,2.82-1.77,3.12-3.73c0.66-0.63,1.39-1.21,1.98-1.89c1.85-2.15,3.76-2.31,6.94-0.51
		c1.6,0.91,2.84,3.93,2.29,5.62c-0.46,1.44-2.47,2.91-4.11,3.01c-1.28,0.08-2.57,0.11-3.84,0.26c-0.38,0.04-0.73,0.38-1.29,0.7
		c1.74,1.78,3.87,1.76,6.09,1.83c-0.23,2.13-1.58,3.39-2.88,4.72c-0.46,0.48-0.67,1.2-1,1.81c-0.46,0.2-0.92,0.4-1.39,0.6
		c-0.07-1.63-0.09-3.26-0.22-4.88c-0.07-0.79-0.35-1.55-0.53-2.33c-0.69,0-1.38,0-2.07-0.01C100.97,23.38,100.28,23.37,99.59,23.37z
		'
      />
      <path
        className='st6'
        fill='#88B47F'
        d='M104.48,30.59c0.46-0.2,0.92-0.4,1.39-0.6c0.7,0.08,1.42,0.31,2.09,0.22c2.03-0.29,4-0.37,6.34,0.44
		c-0.52,0.48-0.78,0.63-0.93,0.86c-0.36,0.55-0.66,1.13-0.98,1.7c0.58-0.07,1.16-0.14,1.73-0.23c1.5-0.24,2.59-2.12,4.47-0.99
		c1.22,0.74,2.43,1.43,2.27,3.04c-0.14,1.46-0.71,2.72-2.46,2.98c-0.73,0.11-1.44,0.4-2.15,0.6c0.18,2.29-0.59,3.17-2.85,3.25
		c-0.77,0.03-1.54,0.05-2.31,0.07c-0.19-1.03-0.38-2.06-0.57-3.1c-1.57-1.01-3.14-2.01-4.71-3.02c-0.99-0.28-1.14,0.33-1.14,1.09
		c0.01,1.67,0.02,3.35,0.03,5.02c-0.11,0.27-0.22,0.55-0.32,0.82c-0.04-1.99-0.07-3.98-0.11-5.97c0-0.34,0-0.69,0-1.03
		C104.33,34.04,104.41,32.31,104.48,30.59z'
      />
      <path
        className='st1'
        fill='#263962'
        d='M119.72,50.31c-0.6-0.6-1.46-1.1-1.75-1.83c-0.5-1.24,1.44-4.91,2.67-5.45c4.66-2.05,8.44-0.02,10.83,3.52
		c1.63,2.41,2.51,4.98,1.06,7.9c-0.26,0.52,0.11,1.38,0.25,2.07c0.28,1.38,0.61,2.74,0.92,4.11c-0.46,0.31-0.91,0.62-1.36,0.93
		c-0.25-0.32-0.48-0.5-0.55-0.73c-0.09-0.32-0.08-0.67-0.09-1.01c-0.26-5.03-0.26-5.03-5.11-5.51c-0.39-1.51-0.78-3.03-1.16-4.54
		c0.33-0.36,1.01-0.91,0.94-1.05c-0.67-1.25-1.47-2.42-2.27-3.69c-0.57,0.56-0.86,0.86-1.15,1.15c-2.34-0.96-3.11-0.5-3.16,1.85
		C119.76,48.78,119.74,49.54,119.72,50.31z'
      />
      <path
        className='st7'
        fill='#F5AE82'
        d='M5.26,21.69c-0.11,2.08,1.8,2.2,3.1,3.15c0,0.83,0,1.76,0,2.69c-0.3,0.23-0.68,0.41-0.88,0.71
		c-1.35,1.94-1.15,4.16-1.12,6.35c-0.2,2.01,0.58,3.74,1.54,5.43c0.53,1.4,0.75,3.06,1.66,4.14c1.06,1.27,0.94,2.38,0.62,4.36
		c-0.96-1.23-1.62-2.03-2.23-2.87c-0.86-1.17-1.69-2.37-2.54-3.55c-0.73-1.29-1.43-2.59-2.21-3.85c-1.43-2.31-2.71-4.42-1.22-7.43
		c1.21-2.45,1.56-5.33,2.36-8C4.45,22.39,4.94,22.07,5.26,21.69z'
      />
      <path
        className='st8'
        fill='#C7B9DB'
        d='M131.69,20.76c0.41,0,0.83,0,1.55,0c-0.78,2.8-1.5,5.37-2.32,8.3c-4.99-0.38-9.44-3.03-14.66-3.35
		c0.76-2.93,1.44-5.54,2.14-8.26c0.45,0.07,0.92,0.14,1.4,0.21c1.18,1.62,2.29,3.29,3.55,4.84c1.14,1.4,2.64,1.36,4.14,0.58
		C128.91,22.35,130.29,21.54,131.69,20.76z'
      />
      <path
        className='st9'
        fill='#7D95CB'
        d='M126.58,54.31c4.85,0.48,4.85,0.48,5.11,5.51c0.02,0.34,0,0.69,0.09,1.01c0.07,0.23,0.3,0.41,0.55,0.73
		c0.45-0.31,0.91-0.62,1.36-0.93c-0.63,3.24-0.63,3.24-1.98,3.93c-0.53,2.01-1.03,3.73-1.41,5.48c-0.09,0.42,0.22,0.93,0.3,1.4
		c0.15,0.93,0.25,1.87,0.38,2.81c-1.49-2.12-2.97-4.25-4.46-6.37c1.85-2.01,3.5-4.12,3.55-7.42c-1.45,0.41-2.51,0.72-3.58,1.02
		c0.35-1.9-1.37-2.58-2.34-3.63c-0.25-0.27-1.45,0.55-2.19,0.49c-1.42-0.11-3.06,1.78-4.27-0.4c0.72-0.71,1.43-1.43,2.15-2.14
		c0.8-0.31,1.61-0.62,2.41-0.93c0.2,0.63-0.07,1.89,1.07,1.49C124.51,55.95,126.06,55.87,126.58,54.31z'
      />
      <path
        className='st10'
        fill='#E88031'
        d='M60.31,22.05c2.41-1.44,4.8-2.91,7.23-4.3c2.28-1.31,4.55-2.48,7.21-0.67c1.94,1.32,4.07,2.36,6.09,3.57
		c0.78,0.47,1.49,1.07,2.23,1.61l-0.21,0.07c-0.64,0.16-1.27,0.31-1.91,0.47c-0.35,0-0.69,0-1.04,0c-0.54-0.17-1.08-0.5-1.62-0.5
		c-5.95-0.01-11.91,0.03-17.86,0.05l-0.14-0.07L60.31,22.05z'
      />
      <path
        className='st7'
        fill='#F5AE82'
        d='M20.88,31.13c-0.46-3.22,1.1-5.89,2.38-8.62c0.07-0.15,0.46-0.15,0.7-0.23c0.98,1.09,2.11,2.08,2.92,3.28
		c1.52,2.25,3.66,3.42,6.2,3.85c0.91,0.15,1.92-0.24,2.88-0.38c1.18,0.84,2.2,0.67,3.07-0.48l0.24,0l0.24-0.03
		c0.74,1.43-0.57,2.08-1.14,3.01c-2.09,0.35-4.16,0.8-6.26,1.02c-3.06,0.31-5.61-0.92-7.8-2.99c-0.6-0.57-1.24-1.08-2.24-1.94
		C21.58,29.04,21.23,30.08,20.88,31.13z'
      />
      <path
        className='st7'
        fill='#F5AE82'
        d='M18.83,18.21c0.3,1.23,0.6,2.47,1.04,4.27c-2.61-0.54-4.79-0.99-7.12-1.48c-0.1-0.84-0.21-1.67-0.36-2.91
		c-1.18,0-2.34,0-3.5,0c1.01-1.89,2.84-0.58,4.59-1.33c0.29-1.06,0.67-2.48,1.06-3.89c-0.02-0.88-0.44-2.12,0.92-2.19
		c2.31-0.12,3.27-1.86,4.54-3.29c1.2-0.31,1.91,0.82,1.61,1.33c-0.85,1.41-0.1,3.3-1.6,4.5c-0.73,0.58-0.04,2.55-2.24,1.84
		C18.1,16.34,16.92,17.71,18.83,18.21z'
      />
      <path
        className='st11'
        fill='#F4BB98'
        d='M117.69,57.94c1.21,2.18,2.85,0.29,4.27,0.4c0.74,0.06,1.94-0.76,2.19-0.49c0.97,1.04,2.68,1.72,2.34,3.63
		c-1.37,0.26-2.74,0.52-4.12,0.77c-2.39,0.11-4.77,0.21-7.16,0.32c-0.64-1.42-1.91-1.41-3.17-1.41c-0.41-1.96-2.43-2.29-3.58-3.51
		c-0.03-0.04-0.48,0.32-0.74,0.5c-1.32-0.42-2.64-0.84-3.73-1.19c0.76-1.23,1.34-2.19,1.93-3.15c1.37,1.53,2.94,2.72,5.01,3.13
		c1.41,0.86,2.82,1.44,4.55,0.76C116.1,57.48,116.95,57.85,117.69,57.94z'
      />
      <path
        className='st8'
        fill='#C7B9DB'
        d='M69.55,1.05c0.86,1.29,1.74,2.56,2.56,3.88c0.38,0.62,0.62,1.34,1.13,2.47c-3.93,1.8-7.86,3.59-11.93,5.45
		c-0.9-2.12-1.58-3.81-2.34-5.47c-0.37-0.81-0.11-1.11,0.68-1.17c1.59,0.18,3.18,0.32,4.76,0.55c1.67,0.25,2.71-0.33,3.3-1.97
		C68.18,3.49,68.92,2.29,69.55,1.05z'
      />
      <path
        className='st12'
        fill='#363D67'
        d='M19.98,7.4c-1.26,1.43-2.23,3.16-4.54,3.29c-1.35,0.07-0.93,1.31-0.92,2.19c-0.16-0.02-0.32-0.05-0.48-0.09
		c-0.23-1.35-0.78,0.11-1.14-0.13c-0.45-0.69-1.17-1.34-1.31-2.09c-0.32-1.74,0.31-3.59-1.45-5.08c-0.82-0.7-0.41-2.16,0.62-2.92
		c0.94-0.69,1.86-0.88,2.9,0.12c0.58,0.56,1.59,0.78,2.44,0.91C18.16,3.88,19.59,5.21,19.98,7.4z'
      />
      <path
        className='st7'
        fill='#F5AE82'
        d='M126.58,54.31c-0.52,1.56-2.08,1.64-3.26,2.06c-1.14,0.4-0.87-0.86-1.07-1.49c0.14-0.46,0.28-0.93,0.35-1.17
		c-1.01-1.09-1.91-2.06-2.81-3.03c-0.04-0.12-0.06-0.24-0.07-0.36c0.02-0.76,0.04-1.52,0.05-2.28c0.05-2.35,0.82-2.81,3.16-1.85
		c0.51,1.42,0.12,3.46,2.49,3.59C125.81,51.28,126.2,52.79,126.58,54.31z'
      />
      <path
        className='st13'
        fill='#BCB3D8'
        d='M131.69,20.76c-1.4,0.78-2.78,1.58-4.2,2.32c-1.5,0.78-3,0.82-4.14-0.58c-1.26-1.55-2.37-3.22-3.55-4.84
		c2.04,0.41,4.1,0.74,6.11,1.26C127.86,19.44,129.76,20.14,131.69,20.76z'
      />
      <path
        className='st5'
        fill='#AACFA1'
        d='M60.31,22.05c0,0-0.03,0.23-0.03,0.23c-0.34,0.32-0.68,0.65-1.03,0.97c-1.42-0.14-2.84-0.29-4.26-0.43
		c0.01-2.1,0.03-4.21,0.04-6.31c0.13-0.1,0.35-0.3,0.37-0.29C57.3,17.94,59.7,19.25,60.31,22.05z'
      />
      <path
        className='st13'
        fill='#BCB3D8'
        d='M69.55,1.05c-0.62,1.25-1.37,2.45-1.84,3.75c-0.6,1.64-1.64,2.21-3.3,1.97c-1.58-0.23-3.17-0.37-4.76-0.55
		c0.38-0.37,0.71-0.86,1.16-1.09c2.64-1.39,5.31-2.72,7.98-4.05C68.99,0.96,69.29,1.05,69.55,1.05z'
      />
      <path
        className='st3'
        fill='#F69848'
        d='M59.25,23.25c0.34-0.32,0.68-0.65,1.03-0.97c0,0,0.14,0.07,0.14,0.07c0.69,0.15,1.39,0.43,2.08,0.44
		c5.8,0.03,11.6,0.01,17.4,0.01c0.35,0,0.69,0,1.04,0c0.64-0.16,1.27-0.31,1.91-0.47c0,0,0.21-0.07,0.21-0.07
		c0.35,0.18,0.7,0.35,1.05,0.53c-0.97,0.68-1.89,1.09-3.21,0.64c-1.01-0.34-2.21-0.09-3.33-0.1C71.47,23.31,65.36,23.28,59.25,23.25
		z'
      />
      <path
        className='st1'
        fill='#263962'
        d='M119.79,50.67c0.9,0.97,1.8,1.95,2.81,3.03c-0.07,0.25-0.21,0.71-0.35,1.17c-0.8,0.31-1.61,0.62-2.41,0.93
		C118.4,54.11,118.75,52.39,119.79,50.67z'
      />
      <path
        className='st7'
        fill='#F5AE82'
        d='M12.91,12.65c0.36,0.24,0.91-1.22,1.14,0.13C13.67,12.74,13.29,12.69,12.91,12.65z'
      />
      <path
        className='st14'
        fill='#F2964B'
        d='M38.44,35.6c1.23,0.27,0.85,1.27,0.92,2.06c0.16,1.82,0.33,3.63,0.49,5.45c0.71,0.35,1.43,0.7,2.14,1.06
		l0.02-0.06c0.18,0.22,0.36,0.45,0.54,0.67c6.51,3.93,13,7.88,19.53,11.78c2.1,1.26,4.29,2.39,6.43,3.57
		C61.21,65.36,53.9,70.6,46.14,76.16c1.37,0,2.28-0.07,3.17,0.02c0.88,0.09,1.75,0.34,2.62,0.52c-1.03,0.01-2.05,0.04-3.08,0.04
		c-3.59-0.01-7.18-0.02-10.77-0.05c-1.01-0.01-2.02,0.1-2,1.39c0.03,1.6,0.25,3.2,0.39,4.8c-0.02,0.15-0.02,0.3,0.02,0.44
		c-1.1,0.18-1.88,0.68-2.02,2.16c1.08,0,2.02,0,2.95,0c18.73,0,37.46,0.01,56.19-0.02c2.74,0,5.49-0.15,8.23-0.24l0.16,0.04
		c-0.88,0.24-1.76,0.68-2.64,0.68c-21.54,0.04-43.07,0.04-64.61,0.03c-0.67,0-1.35-0.1-2.3-0.17c-0.07-1.42-0.19-2.76-0.19-4.1
		c-0.02-10.17-0.05-20.33,0.03-30.5c0.01-1.82-0.27-3.35-1.68-4.57c1.35-1.24,1.93-2.74,1.64-4.58c-0.1-0.67-0.28-1.39-0.13-2.02
		c0.11-0.47,0.69-0.84,1.06-1.25c1.74,1.25,3.49,2.5,5.23,3.75c0.22-0.11,0.45-0.23,0.67-0.34c-0.2-2.14-0.4-4.27-0.61-6.41
		L38.44,35.6z M50.46,63.3c-1.61-1.94-3.91-1.94-5.84-2.52c-2.06-0.63-2.47,0.02-1.84,2.2c0.11,0.38,0.14,0.77,0.17,0.95
		c-1.48,0.98-2.8,1.86-4.12,2.74c0.1,0.19,0.2,0.37,0.3,0.56c0.89-0.13,2.09-0.69,2.61-0.32c1.53,1.09,2.58,0.02,3.66-0.51
		C47.05,65.59,48.55,64.49,50.46,63.3z'
      />
      <path
        className='st1'
        fill='#263962'
        d='M112.04,61.16c1.26,0.01,2.53,0,3.17,1.41c-0.21,0.79-0.46,1.57-0.61,2.37c-0.78,4.08-1.54,8.16-2.31,12.24
		c-0.16,0.83-0.22,1.71-0.55,2.48c-0.48,1.11-1.16,2.14-1.76,3.2c-0.88,0.72-1.76,1.44-2.64,2.15c-1.73,1.04-3.5,1.45-5.34,0.25
		l-0.16-0.04c0.49-0.24,1.01-0.43,1.46-0.73c0.56-0.38,1.07-0.84,1.6-1.27c0.14-0.1,0.37-0.19,0.4-0.32
		c0.81-4.08,3.21-7.64,3.81-11.79c0.36-2.5,0.82-4.98,1.24-7.47C110.91,62.83,111.48,62,112.04,61.16z'
      />
      <path
        className='st6'
        fill='#88B47F'
        d='M33.19,38.8c-0.37,0.41-0.94,0.78-1.06,1.25c-0.15,0.63,0.02,1.35,0.13,2.02c0.28,1.83-0.29,3.34-1.64,4.58
		c-2.64,0.52-4.08-1.08-5.36-3.02c0.36-1.1,2.8,0.3,2.2-2.08c-1.1-0.06-2.27-0.12-3.63-0.2c-0.22-0.56-0.68-1.18-0.65-1.78
		c0.06-1.12,0.1-2.91,0.71-3.19c1.16-0.52,2.79-0.45,4.07-0.08c0.79,0.23,1,0.32,1.83-0.08c1.02-0.49,2.53-0.02,3.82,0.13
		c0.56,0.07,1.09,0.39,1.63,0.59c-0.31,0.28-0.63,0.56-0.94,0.84C33.93,38.12,33.56,38.46,33.19,38.8z'
      />
      <path
        className='st0'
        fill='#253D6C'
        d='M17.71,40.5c0.8-0.26,1.61-0.52,2.41-0.78c0.25,3.67,0.51,7.35,0.76,11.02c-0.24,0.03-0.48,0.06-0.67,0.09
		c-1.72,3.16-3.51,6.45-5.36,9.84c-1.66-0.82-1.25-1.98-1-3.15c0.31-1.41,0.78-2.84,0.74-4.25c-0.07-2.7,0.77-5.09,1.85-7.46
		c0.17-0.37,0.22-0.82,0.25-1.24c0.08-1.14-0.35-2.49,1.32-2.9C17.91,41.28,17.81,40.89,17.71,40.5z'
      />
      <path
        className='st1'
        fill='#263962'
        d='M118.2,80.27c0.35-1.03,0.71-2.06,1.06-3.09c0.29,0.14,0.75,0.2,0.86,0.42c1.3,2.76,2.55,5.54,3.82,8.31
		c-1.19,0-2.39,0-3.58,0C119.63,84.03,118.91,82.15,118.2,80.27z'
      />
      <path
        className='st10'
        fill='#E88031'
        d='M33.19,38.8c0.37-0.34,0.74-0.68,1.12-1.02c0.31-0.28,0.63-0.56,0.94-0.84c1.08-0.38,2.16-0.76,3.23-1.14
		c0.2,2.14,0.4,4.27,0.61,6.41c-0.22,0.11-0.45,0.23-0.67,0.34C36.68,41.3,34.93,40.05,33.19,38.8z'
      />
      <path
        className='st12'
        fill='#363D67'
        d='M107.34,85.01c0.88-0.72,1.76-1.44,2.64-2.15c1.06,0.2,2.12,0.39,3.18,0.59c0.63,2.21,0.48,2.52-1.56,2.54
		C110.12,86,108.52,86.39,107.34,85.01z'
      />
      <path
        className='st0'
        fill='#253D6C'
        d='M123.93,85.92c-1.26-2.77-2.52-5.56-3.82-8.31c-0.11-0.22-0.56-0.28-0.86-0.42c0.35-0.69,0.7-1.37,1.05-2.06
		c1.03,0.35,2.06,0.7,3.09,1.05c0,0.34,0.01,0.68,0.01,1.02c0,0.69,0,1.38,0.01,2.07c0.21,3.93,2.41,6.08,6.33,6.23
		c0.48,0.02,0.95,0.23,1.43,0.35C128.76,85.87,126.35,85.89,123.93,85.92z'
      />
      <path
        className='st15'
        fill='#ED7D2A'
        d='M104.9,83.23c-0.53,0.43-1.04,0.89-1.6,1.27c-0.45,0.3-0.97,0.49-1.46,0.73c-2.74,0.08-5.49,0.23-8.23,0.24
		c-18.73,0.02-37.46,0.01-56.19,0.02c-0.94,0-1.87,0-2.95,0c0.15-1.48,0.93-1.98,2.02-2.16l0.22,0.01l0.2-0.08
		c0.33,0.01,0.66,0.02,1,0.03c4.43,0.04,8.86,0.09,13.28,0.11c0.29,0,0.59-0.26,0.88-0.4c0.61-2.11,0.9-4.21-0.16-6.28
		c-0.87-0.18-1.74-0.43-2.62-0.52c-0.89-0.09-1.79-0.02-3.17-0.02c7.76-5.56,15.07-10.79,22.37-16.03c2.06-1.3,4.14-1.31,6.25-0.11
		c6.09,4.32,12.19,8.62,18.26,12.97c3.46,2.48,6.81,5.13,10.32,7.54C104.41,81.26,104.83,82.06,104.9,83.23z'
      />
      <path
        className='st16'
        fill='#F0CBAC'
        d='M85.08,32.14c1.66,0.24,3.31,0.48,4.95,0.72c-0.02,0.24-0.01,0.33-0.04,0.41c-0.02,0.07-0.08,0.17-0.13,0.18
		c-0.32,0.08-0.65,0.21-0.98,0.21c-11.51,0-23.02-0.01-34.53-0.03c-0.21,0-0.42-0.19-0.68-0.84c1.84-0.21,3.68-0.43,5.51-0.64
		c0.77,0.01,1.58,0.08,1.58-1.06c2.4,0,4.79,0,7.19,0c0.76,0.34,1.5,0.85,2.29,0.95c0.42,0.05,0.93-0.61,1.4-0.95
		c1,0.18,1.99,0.36,2.99,0.53c1.24,1.75,2.76,1.16,4.29,0.52c1,0,1.99,0,2.99,0C82.98,32.14,84.03,32.14,85.08,32.14z'
      />
      <path
        className='st17'
        fill='#F1D0B6'
        d='M81.92,32.14c-1,0-1.99,0-2.99,0c-1.43-0.17-2.86-0.34-4.29-0.52c-1-0.18-1.99-0.36-2.99-0.53
		c-0.36,0-0.72,0.01-1.07,0.01c-0.17-0.01-0.33-0.01-0.5,0c-0.7,0-1.41-0.01-2.11-0.01c-2.4,0-4.79,0-7.19,0
		c-0.52-0.01-1.05-0.01-1.57-0.02c0.17-0.34,0.34-0.69,0.5-1.03c0.91-0.16,1.82-0.47,2.74-0.47c7.2-0.03,14.4-0.01,21.6-0.01
		c0,0.16,0.01,0.33,0.02,0.49c-0.35,0.35-0.7,0.69-1.06,1.04C82.64,31.45,82.28,31.79,81.92,32.14z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M79.64,26.26c-8.79,0-17.57,0-26.81,0c0.76-0.84,1.23-1.37,1.4-1.57c2.09,0.26,3.82,0.65,5.55,0.67
		c5.93,0.08,11.86,0.02,17.79,0.04c0.72,0,1.44,0.17,2.16,0.26C79.7,25.86,79.67,26.06,79.64,26.26z'
      />
      <path
        className='st19'
        fill='#EAD1BC'
        d='M59.7,41.46c-0.29-0.11-0.58-0.22-1.03-0.38c1.72-0.8,23.62-0.99,26.65-0.34c0,0.09,0.01,0.18,0,0.26
		c-0.02,0.07-0.06,0.19-0.1,0.2c-0.75,0.09-1.51,0.24-2.26,0.24c-7.23,0.02-14.47,0.02-21.7,0.02
		C60.74,41.46,60.22,41.46,59.7,41.46z'
      />
      <path
        className='st17'
        fill='#F1D0B6'
        d='M90,43.84c-8.62,0-17.23,0-26,0c1.2-0.91,4.3-1.07,8.39-0.85c2.98,0.16,5.98,0.02,8.97,0.01
		c2.88,0,5.77,0,8.65,0C90.01,43.28,90,43.56,90,43.84z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M84.04,29.56c-7.2,0-14.4-0.03-21.6,0.01c-0.91,0-1.82,0.31-2.74,0.47c-0.09-1.06,0.31-1.56,1.46-1.55
		c7.15,0.03,14.31,0,21.46,0.06C83.1,28.55,83.57,29.21,84.04,29.56z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M98.79,26.2c-5.51,0-11.02,0-17.04,0C83.8,25.27,95.88,25.09,98.79,26.2z'
      />
      <path
        className='st19'
        fill='#EAD1BC'
        d='M72.92,46.31c-4.83,0.29-9.66,0.38-14.49,0.04C60.11,45.45,70.53,45.35,72.92,46.31z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M41.99,44.16c-0.71-0.35-1.43-0.7-2.14-1.06c0.22-0.34,0.6-0.66,0.65-1.02c0.42-3.31,0.79-6.63,1.5-9.94
		C41.99,36.15,41.99,40.16,41.99,44.16z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M104.48,30.59c-0.07,1.72-0.15,3.44-0.22,5.16c-0.18-1.04-0.45-2.08-0.51-3.13c-0.09-1.6-0.02-3.21-0.02-5.02
		c-0.87,0.08-1.49,0.13-2.21,0.19c-0.4-0.62-0.83-1.29-1.22-1.91c0.58-1.72,3.87,0.55,3.44-2.5c0.18,0.78,0.46,1.54,0.53,2.33
		C104.39,27.34,104.41,28.97,104.48,30.59z'
      />
      <path
        className='st19'
        fill='#EAD1BC'
        d='M85.86,46.38c-3.79,0-7.58,0-11.62,0C75.98,45.38,83.79,45.3,85.86,46.38z'
      />
      <path
        className='st17'
        fill='#F1D0B6'
        d='M61.26,42.98c0.23,0.07,0.46,0.14,0.69,0.21c-2.5,1.24-5.09,0.7-7.68,0.46c1.72-0.98,3.63-0.47,5.44-0.67
		C60.22,42.98,60.74,42.98,61.26,42.98z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M81.92,32.14c0.36-0.35,0.72-0.7,1.08-1.05c0.35-0.35,0.7-0.69,1.06-1.04c1.48,0.14,0.89,1.29,1.03,2.09
		C84.03,32.14,82.98,32.14,81.92,32.14z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M104.26,36.79c0.04,1.99,0.07,3.98,0.11,5.97c-0.21,0.08-0.42,0.16-0.64,0.24
		C103.91,40.93,104.09,38.86,104.26,36.79z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M61.26,42.98c-0.52,0-1.04,0-1.55,0c0-0.5,0-1.01,0-1.51c0.52,0,1.04,0,1.55,0
		C61.26,41.97,61.26,42.47,61.26,42.98z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M99.59,23.37c0.69,0,1.38,0.01,2.07,0.01c-0.39,0.32-0.74,0.79-1.18,0.89
		C100.27,24.32,99.89,23.69,99.59,23.37z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M39.51,28.51l-0.24,0.03c0,0-0.24,0-0.24,0c0.15-1.07,0.3-2.15,0.44-3.22
		C39.48,26.38,39.49,27.45,39.51,28.51z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M59.2,31.07c0.52,0.01,1.05,0.01,1.57,0.02c0,1.13-0.81,1.06-1.58,1.06C59.19,31.79,59.2,31.43,59.2,31.07z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M41.94,25.52c-0.13,0.07-0.27,0.15-0.4,0.22c-0.07-0.19-0.14-0.37-0.22-0.56c0.15-0.03,0.34-0.14,0.43-0.08
		C41.86,25.17,41.88,25.38,41.94,25.52z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M51.77,25.43c-0.11,0.1-0.22,0.19-0.34,0.29c-0.11-0.17-0.21-0.34-0.32-0.52c0.14-0.06,0.31-0.19,0.43-0.15
		C51.65,25.09,51.7,25.3,51.77,25.43z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M43.47,25.91c-0.1,0.11-0.2,0.22-0.3,0.33c-0.13-0.16-0.26-0.33-0.39-0.49c0.14-0.07,0.29-0.2,0.42-0.18
		C43.31,25.59,43.38,25.79,43.47,25.91z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M41.42,29.15c-0.13,0.07-0.27,0.15-0.4,0.22c-0.07-0.19-0.14-0.37-0.22-0.56c0.15-0.03,0.34-0.14,0.43-0.08
		C41.34,28.8,41.36,29,41.42,29.15z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M42.55,44.77c-0.18-0.22-0.36-0.45-0.54-0.67C42.19,44.32,42.37,44.55,42.55,44.77z'
      />
      <path
        className='st20'
        fill='#EDEFEF'
        d='M105.93,53.82c-0.59,0.96-1.17,1.92-1.93,3.15c1.09,0.35,2.41,0.77,3.73,1.19c0.37,1.49,0.75,2.98,1.12,4.47
		c-2.23,0-4.46,0-7.01,0c-0.63-2.25-1.28-4.55-2.03-7.22c-0.36-0.18-1.22-0.62-2.62-1.33c1.16-0.35,1.68-0.59,2.23-0.65
		c1.62-0.2,3.24-0.4,4.86-0.43C104.83,52.99,105.38,53.53,105.93,53.82z'
      />
      <path
        className='st10'
        fill='#E88031'
        d='M105.8,35.83c1.57,1.01,3.14,2.01,4.71,3.02c-1.27,1.41-2.71,2.49-4.69,2.63
		C105.82,39.6,105.81,37.71,105.8,35.83z'
      />
      <path
        className='st7'
        fill='#F5AE82'
        d='M108.84,62.63c-0.37-1.49-0.75-2.98-1.12-4.47c0.26-0.17,0.71-0.53,0.74-0.5c1.15,1.22,3.17,1.55,3.58,3.51
		c-0.57,0.83-1.13,1.66-1.7,2.49C109.84,63.31,109.34,62.97,108.84,62.63z'
      />
      <path
        className='st15'
        fill='#ED7D2A'
        d='M105.8,35.83c0.01,1.88,0.01,3.77,0.02,5.65c-0.38,0.15-0.75,0.31-1.13,0.46c-0.01-1.67-0.02-3.35-0.03-5.02
		C104.66,36.16,104.81,35.55,105.8,35.83z'
      />
      <path
        className='st10'
        fill='#E88031'
        d='M7.9,40.02c-0.95-1.68-1.74-3.42-1.54-5.43c0.72-1.16,1.45-2.33,2.17-3.49c0.21-0.53,0.41-1.06,0.62-1.6
		c0.14-0.02,0.27-0.04,0.41-0.05c0.07,0.87,0.28,1.76,0.17,2.61c-0.35,2.59-0.83,5.17-1.26,7.76C8.28,39.88,8.09,39.95,7.9,40.02z'
      />
      <path
        className='st21'
        fill='#5E84C2'
        d='M122.37,62.26c1.37-0.26,2.74-0.52,4.12-0.77c1.07-0.3,2.13-0.61,3.58-1.02c-0.04,3.3-1.7,5.4-3.55,7.42
		C125.14,66,123.76,64.13,122.37,62.26z'
      />
      <path
        className='st22'
        fill='#26437F'
        d='M123.4,76.17c-1.03-0.35-2.06-0.7-3.09-1.05c0.41-1.26,0.78-2.53,1.28-3.75c0.09-0.22,0.8-0.38,1.12-0.27
		c0.3,0.1,0.64,0.59,0.65,0.93C123.44,73.41,123.4,74.79,123.4,76.17z'
      />
      <path
        className='st22'
        fill='#26437F'
        d='M123.42,79.27c0-0.69,0-1.38-0.01-2.07c0.34,0.3,1,0.7,0.95,0.88C124.22,78.53,123.76,78.88,123.42,79.27z'
      />
      <path
        className='st0'
        fill='#253D6C'
        d='M125.42,49.76c-2.37-0.13-1.97-2.18-2.49-3.59c0.29-0.29,0.59-0.58,1.15-1.15c0.81,1.27,1.61,2.44,2.27,3.69
		C126.43,48.86,125.75,49.4,125.42,49.76z'
      />
      <path
        className='st15'
        fill='#ED7D2A'
        d='M79.91,22.81c-5.8,0-11.6,0.02-17.4-0.01c-0.69,0-1.39-0.28-2.08-0.44c5.95-0.02,11.91-0.06,17.86-0.05
		C78.82,22.31,79.37,22.63,79.91,22.81z'
      />
      <path
        className='st15'
        fill='#ED7D2A'
        d='M82.85,22.34c-0.64,0.16-1.27,0.31-1.91,0.47C81.58,22.65,82.22,22.49,82.85,22.34z'
      />
      <path
        className='st23'
        fill='#F9E3CE'
        d='M51.93,76.7c1.06,2.07,0.76,4.17,0.16,6.28c-3.85-0.2-7.7-0.46-11.56-0.57c-0.88-0.03-2.16-0.67-2.6,0.86
		c-0.33-0.01-0.66-0.02-1-0.03c-0.15-0.12-0.3-0.24-0.45-0.37c-0.14-1.6-0.36-3.19-0.39-4.8c-0.03-1.29,0.99-1.4,2-1.39
		c3.59,0.02,7.18,0.04,10.77,0.05C49.87,76.74,50.9,76.71,51.93,76.7z'
      />
      <path
        className='st20'
        fill='#EDEFEF'
        d='M50.46,63.3c-1.91,1.19-3.41,2.29-5.06,3.1c-1.08,0.53-2.13,1.61-3.66,0.51c-0.53-0.38-1.72,0.18-2.61,0.32
		c-0.1-0.19-0.2-0.37-0.3-0.56c1.32-0.88,2.64-1.76,4.12-2.74c-0.03-0.18-0.06-0.58-0.17-0.95c-0.63-2.18-0.22-2.83,1.84-2.2
		C46.55,61.37,48.85,61.36,50.46,63.3z'
      />
      <path
        className='st16'
        fill='#F0CBAC'
        d='M36.47,82.88c0.15,0.12,0.3,0.24,0.45,0.37l-0.2,0.08l-0.22-0.01C36.46,83.17,36.45,83.03,36.47,82.88z'
      />
      <path
        className='st16'
        fill='#F0CBAC'
        d='M37.92,83.27c0.44-1.53,1.72-0.89,2.6-0.86c3.85,0.11,7.71,0.37,11.56,0.57c-0.29,0.14-0.59,0.4-0.88,0.4
		C46.77,83.36,42.35,83.31,37.92,83.27z'
      />
      <path
        className='st23'
        fill='#F9E3CE'
        d='M74.64,31.62c1.43,0.17,2.86,0.34,4.29,0.52C77.4,32.78,75.88,33.37,74.64,31.62z'
      />
      <path
        className='st23'
        fill='#F9E3CE'
        d='M67.96,31.09c0.7,0,1.41,0.01,2.11,0.01c0.17,0,0.33,0,0.5,0c0.36,0,0.72-0.01,1.07-0.01
		c-0.47,0.34-0.98,1-1.4,0.95C69.46,31.94,68.72,31.43,67.96,31.09z'
      />
      <path
        className='st18'
        fill='#F8E8D7'
        d='M70.58,31.11c-0.17,0-0.33,0-0.5,0C70.24,31.1,70.41,31.1,70.58,31.11z'
      />
    </g>
  </svg>
);

TallyMailIllustration.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export { TallyMailIllustration };
