import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const HoverableIcon = ({
  icon,
  customColors = ['grey', 'black'],
  ...props
}) => {
  const [color, setColor] = useState(customColors[0]);

  const handleMouseEnter = () => {
    setColor(customColors[1]);
  };
  const handleMouseLeave = () => {
    setColor(customColors[0]);
  };

  return (
    <FontAwesomeIcon
      {...props}
      icon={icon}
      className='ms-1'
      style={{ cursor: 'pointer' }}
      color={color}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

HoverableIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  customColors: PropTypes.array,
};

export default HoverableIcon;

