import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { Input } from 'reactstrap';

import PropTypes from 'prop-types';

const ControlledInput = ({
  disabled = false,
  autoFocus,
  control,
  label,
  paramName,
  required = false,
  placeholder = '',
  textArea = false,
  textAreaRows = 5,
  validate,
  number = false,
  maxLength,
  type,
  forceLowerCase = false,
}) => {
  return (
    <Fragment>
      {label && <Form.Label className='mt-3'>{label}</Form.Label>}
      <Controller
        control={control}
        name={paramName}
        rules={{ required, validate }}
        render={({ field }) => {
          return (
            <Input
              maxLength={maxLength}
              disabled={disabled}
              autoFocus={autoFocus}
              value={field.value}
              onChange={(e) => {
                if (number) {
                  field.onChange(parseFloat(e.target.value));
                } else {
                  field.onChange(
                    forceLowerCase
                      ? `${e.target.value}`.toLowerCase()
                      : e.target.value
                  );
                }
              }}
              placeholder={placeholder}
              type={number ? 'number' : textArea ? 'textarea' : type || 'text'}
              rows={textAreaRows}
            />
          );
        }}
      />
    </Fragment>
  );
};
ControlledInput.propTypes = {
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  paramName: PropTypes.string,
  label: PropTypes.string,
  control: PropTypes.object,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  placeholder: PropTypes.string,
  textArea: PropTypes.bool,
  textAreaRows: PropTypes.number,
  validate: PropTypes.any,
  onlyNumeric: PropTypes.bool,
  number: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default ControlledInput;

