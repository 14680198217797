import React from 'react';

import PropTypes from 'prop-types';

import { NOTIFICATION_TYPES } from 'blockscope/components/notification/static/types';

function NotificationContent({ type, data }) {
  const getCapitalName = (name) => {
    return name
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  switch (type) {
    case NOTIFICATION_TYPES.USER_PASSWORD_CHANGED:
      return (
        <>
          <p>
            <b>{getCapitalName(data.name)}</b> changed the user password.
          </p>
        </>
      );
    case NOTIFICATION_TYPES.USER_JOINED_ORGANIZATION:
      return (
        <>
          <p>
            <b>{getCapitalName(data.userName)}</b> joined organization
            <b> {data.orgName}</b>.
          </p>
        </>
      );
    case NOTIFICATION_TYPES.WATCHTOWER_CREATED:
      return (
        <>
          <p>
            <b>{getCapitalName(data.triggerUser.name)}</b> created watchtower
            called <b>{data.watchtowerName}</b>.
          </p>
        </>
      );
    case NOTIFICATION_TYPES.WATCHTOWER_DELETED:
      return (
        <>
          <p>
            <b>{getCapitalName(data.triggerUser.name)}</b> deleted watchtower
            called <b>{data.watchtowerName}</b>.
          </p>
        </>
      );
    case NOTIFICATION_TYPES.PIPELINE_CREATED:
      return (
        <>
          <p>
            <b>{getCapitalName(data.triggerUser.name)}</b> created pipeline
            called <b>{data.pipelineName}</b>.
          </p>
        </>
      );
    case NOTIFICATION_TYPES.PIPELINE_DELETED:
      return (
        <>
          <p>
            <b>{getCapitalName(data.triggerUser.name)}</b> deleted pipeline
            called <b>{data.pipelineName}</b>.
          </p>
        </>
      );
    default:
      return null;
  }
}

NotificationContent.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
};

export default NotificationContent;

