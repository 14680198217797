import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Section from 'blockscope/components/common/Section';

import FooterStandard from 'blockscope/components/pages/landing/components/FooterStandard';
import NavbarTallyPage from 'blockscope/components/pages/landing/components/NavbarTallyPage';

const TallyRegisterationPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [title, setTitle] = useState('Signup');

  const handleFormSubmission = (event) => {
    // Handle the form submission message here
    if (
      typeof event?.data === 'string' &&
      event?.data?.includes('Tally.FormSubmitted')
    ) {
      const eventData = JSON.parse(event.data);
      if (eventData?.event === 'Tally.FormSubmitted') {
        // Form submitted successfully
        const firstName = eventData.payload.fields[0].answer.value;
        const lastName = eventData.payload.fields[1].answer.value;
        const email = eventData.payload.fields[2].answer.value;
        const wantDemo = eventData.payload.fields[9].answer.value;

        if (wantDemo.toLowerCase().includes('demo')) {
          navigate(
            `/calendly?firstName=${firstName}&lastName=${lastName}&email=${email}&redirect=register`
          );
        } else {
          navigate(
            `/register?firstName=${firstName}&lastName=${lastName}&email=${email}`
          );
        }
      }
    }
  };
  useEffect(() => {
    window.addEventListener('message', handleFormSubmission);
    return () => {
      window.removeEventListener('message', handleFormSubmission);
    };
  }, []);

  useEffect(() => {
    if (searchParams.get('from') === 'learnmore') {
      setTitle('Learn More');
    } else if (searchParams.get('from') === 'products') {
      setTitle('Learn More about Blockscope Products');
    } else if (searchParams.get('from') === 'demo') {
      setTitle('Request a Demo');
    } else {
      setTitle('Signup');
    }
  }, [searchParams]);

  window.scrollTo(0, 0);
  // Tally.loadEmbeds();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://tally.so/widgets/embed.js';
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <NavbarTallyPage />

      <Section className={'bg-white'}>
        <h1 className={''}>
          <b>{title}</b>
        </h1>{' '}
        {/* <Card>
          <Card.Body> */}{' '}
        <iframe
          data-tally-src='https://tally.so/embed/31kJ8W?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1'
          loading='lazy'
          width='100%'
          height='2072'
          title='Blockscope Registration Form'
        ></iframe>
        {/* </Card.Body>
        </Card> */}
      </Section>

      <FooterStandard />
    </>
  );
};

export default TallyRegisterationPage;
