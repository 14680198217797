import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { logout_Action } from 'stateManagement/redux/actions/authActions.js';

import { PROFILE_DROPDOWN_TOAST_ID } from 'blockscope/static/toastIds.js';

import Avatar from 'blockscope/components/common/Avatar';

const ProfileDropdown = ({
  // reducer
  authReducer: { user },

  // action
  logout_Action,
}) => {
  return (
    <Dropdown navbar={true} as='li'>
      <Dropdown.Toggle
        bsPrefix='toggle'
        className='bg-transparent pe-0 ps-2 nav-link border-0 shadow-none'
      >
        <Avatar
          name={user ? `${user.firstName} ${user.lastName}` : ''}
          size='xl'
          className='status-online'
          hideTooltip
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdown-caret dropdown-menu-card  dropdown-menu-end'>
        <div className='bg-white rounded-2 py-2 dark__bg-1000'>
          <Dropdown.Item className='fw-bold text-primary text-capitalize'>
            {`${user.firstName} ${user.lastName}`}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to='/user-settings'>
            My Settings
          </Dropdown.Item>
          <Dropdown.Item as={Link} to='/select-org'>
            View My Invites
          </Dropdown.Item>
          <Dropdown.Item as={Link} to='/guide'>
            Guides
          </Dropdown.Item>
          <Dropdown.Divider />

          <Dropdown.Item
            as={Link}
            to='/'
            onClick={() => {
              toast.success(
                `Successfully Logged out!`,
                PROFILE_DROPDOWN_TOAST_ID.SUCCESS
              );
              logout_Action();
            }}
            className='text-danger'
          >
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

ProfileDropdown.propTypes = {
  logout_Action: PropTypes.func.isRequired,
  authReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
});

export default connect(mapStateToProps, { logout_Action })(ProfileDropdown);

