import { ORG_SERVICES } from 'blockscope/static/blockscopeServices.js';
import { OPEN_PAGE_POSTHOG } from 'blockscope/static/posthogEvents';

import { extractAddress } from 'blockscope/utils/helperUtils';

export const QUERY_TYPE_LABELS = {
  [ORG_SERVICES.TRANSACTION_DECODER]: `Transaction Decoder`,
  [ORG_SERVICES.TRANSACTION_SIMULATOR]: `Transaction Simulator`,
  [ORG_SERVICES.BLOCK_BREAKDOWN]: `Block Breakdown`,
  [ORG_SERVICES.WATCHTOWER]: `Watchtower Document`,
  [ORG_SERVICES.CONTRACT_BREAKDOWN]: `Contract Analysis`,
  [ORG_SERVICES.CONTRACT_USAGE]: `Contract Usage`,
  [ORG_SERVICES.ADDRESS_ACTIVITY]: `Wallet Profile`,
  [ORG_SERVICES.ENTITY_INTERACTION]: `Entity Interaction`,
};

export const QUERY_TYPE_LINKS = {
  [ORG_SERVICES.TRANSACTION_DECODER]: (params) => {
    return `/transaction-decoder/${
      params.chain
    }/${params.transactionHash?.toLowerCase()}`;
  },
  [ORG_SERVICES.TRANSACTION_SIMULATOR]: (params) => {
    return `/transaction-simulator/${params.chain}?from=${
      params.from?._id || params.from
    }&to=${params.to?._id || params.to}&value=${params.value}&gas=${
      params.gas
    }&gasPrice=${params.gasPrice}&input=${params.input}&txnIndex=${
      params.txnIndex
    }&block=${params.block}`;
  },
  [ORG_SERVICES.BLOCK_BREAKDOWN]: (params) => {
    return `/block-breakdown/${params.chain}/${params.blockNumber}`;
  },
  [ORG_SERVICES.WATCHTOWER]: (params) => {
    return `/watchtower-details/${params.watchtower}`;
  },
  [ORG_SERVICES.CONTRACT_BREAKDOWN]: (params) => {
    return `/contract-analysis/${params.chain}/${extractAddress(
      params.contractAddress
    )?.toLowerCase()}`;
  },
  [ORG_SERVICES.CONTRACT_USAGE]: (params) => {
    return `/contract-usage/${params.chain}/${extractAddress(
      params.contractAddress
    )?.toLowerCase()}/?startTime=${params.startTime}&endTime=${params.endTime}`;
  },
  [ORG_SERVICES.ADDRESS_ACTIVITY]: (params) => {
    return `/wallet-profile/${params.chain}/${extractAddress(
      params.address
    )?.toLowerCase()}`;
  },
  [ORG_SERVICES.ENTITY_INTERACTION]: (params) => {
    const tokensString =
      params.tokens?.length > 0
        ? `&tokens=${params.tokens.map((t) => extractAddress(t)).join(',')}`
        : '';
    return `/entity-interaction/${params.chain}/${
      params.queryType
    }/?entityA=${params.entityA
      .map((e) => e._id || e)
      .join(',')}&entityB=${params.entityB
      .map((e) => e._id || e)
      .join(',')}${tokensString}&startDate=${params.startDate}&endDate=${
      params.endDate
    }`;
  },
};

export const QUERY_TYPE_ICONS = {
  [ORG_SERVICES.TRANSACTION_DECODER]: `lock-open`,
  [ORG_SERVICES.TRANSACTION_SIMULATOR]: `terminal`,
  [ORG_SERVICES.BLOCK_BREAKDOWN]: `cubes`,
  [ORG_SERVICES.WATCHTOWER]: `desktop`,
  [ORG_SERVICES.CONTRACT_BREAKDOWN]: `puzzle-piece`,
  [ORG_SERVICES.CONTRACT_USAGE]: `chart-line`,
  [ORG_SERVICES.ADDRESS_ACTIVITY]: `address-card`,
  [ORG_SERVICES.ENTITY_INTERACTION]: `arrow-right-arrow-left`,
};

export const QUERY_TYPE_ICON_COLORS = {
  [ORG_SERVICES.TRANSACTION_DECODER]: `primary`,
  [ORG_SERVICES.TRANSACTION_SIMULATOR]: `secondary`,
  [ORG_SERVICES.BLOCK_BREAKDOWN]: `info`,
  [ORG_SERVICES.WATCHTOWER]: `success`,
  [ORG_SERVICES.CONTRACT_BREAKDOWN]: `success`,
  [ORG_SERVICES.CONTRACT_USAGE]: `warning`,
  [ORG_SERVICES.ADDRESS_ACTIVITY]: `danger`,
  [ORG_SERVICES.ENTITY_INTERACTION]: `info`,
  [ORG_SERVICES.TRACER_TOKENFLOW]: `warning`,
};

export const queryTypeToPostHog = {
  [ORG_SERVICES.TRANSACTION_DECODER]: OPEN_PAGE_POSTHOG.TRANSACTION_DECODER,
  [ORG_SERVICES.TRANSACTION_SIMULATOR]: OPEN_PAGE_POSTHOG.TRANSACTION_SIMULATOR,
  [ORG_SERVICES.BLOCK_BREAKDOWN]: OPEN_PAGE_POSTHOG.BLOCK_BREAKDOWN,
  [ORG_SERVICES.WATCHTOWER]: OPEN_PAGE_POSTHOG.WATCHTOWER.DETAILS,
  [ORG_SERVICES.CONTRACT_BREAKDOWN]: OPEN_PAGE_POSTHOG.CONTRACT_BREAKDOWN,
  [ORG_SERVICES.CONTRACT_USAGE]: OPEN_PAGE_POSTHOG.CONTRACT_USAGE,
  [ORG_SERVICES.ADDRESS_ACTIVITY]: OPEN_PAGE_POSTHOG.WALLET_PROFILE,
  [ORG_SERVICES.ENTITY_INTERACTION]: OPEN_PAGE_POSTHOG.ENTITY_INTERACTION,
};

