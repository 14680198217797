import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DASHBOARD_TOAST_ID } from 'blockscope/static/toastIds';

const EnabledServiceRoute = ({
  // parent params
  children,

  // self params
  service,

  // reducer
  orgState: { orgDetails },
}) => {
  const { orgServices } = orgDetails.org;

  if (orgServices && orgServices.includes(service)) {
    return children;
  } else {
    toast.error('Contact Blockscope to get access.', {
      toastId: DASHBOARD_TOAST_ID.SERVICE_DISABLED,
    });
    return <Navigate to='/all-blockscope-services' />;
  }
};

EnabledServiceRoute.propTypes = {
  orgState: PropTypes.object.isRequired,
  service: PropTypes.string.isRequired,
  children: PropTypes.object,
};

EnabledServiceRoute.defaultProps = {
  addOrgToURL: false,
};

const mapStateToProps = (state) => ({
  orgState: state.orgReducer,
});

export default connect(mapStateToProps)(EnabledServiceRoute);
