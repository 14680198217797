import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import AppContext from 'context/Context';

const NavbarDropdownCommunity = () => {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig,
  } = useContext(AppContext);

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  const communityOptions = [
    {
      name: 'Watchtowers',
      to: '/community/watchtowers',
      exact: true,
      active: true,
    },
    {
      name: 'Blog',
      to: '/blog',
      exact: true,
      active: true,
    },
  ];

  return (
    <>
      {communityOptions.map((route) => (
        <Dropdown.Item
          key={route.name}
          as={Link}
          className={route.active ? 'link-600' : 'text-500'}
          to={route.to}
          onClick={handleDropdownItemClick}
        >
          {route.name}
        </Dropdown.Item>
      ))}
    </>
  );
};

NavbarDropdownCommunity.propTypes = {};

export default NavbarDropdownCommunity;

