import React, { Fragment, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { contactUs_Action } from 'stateManagement/redux/actions/otherActions';

const MailingListForm = ({ contactUs_Action }) => {
  const ContactUsSchema = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    message: Yup.string().max(2500).required('Message is required'),
  });
  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      message: 'Signed up for mail list.',
    },
    resolver: yupResolver(ContactUsSchema),
  });
  const name = watch('name');
  const email = watch('email');
  const onSubmit = async (values) => {
    contactUs_Action(
      values.name,
      values.email,
      values.message,
      setSubmitLoading
    );
    clearErrors();
    setValue('name', '');
    setValue('email', '');
    setValue('message', 'Signed up for mail list.');
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Control
          type='text'
          size='md'
          placeholder='Name'
          className='mb-3'
          {...register('name')}
        />
        {errors.name && (
          <p className='text-danger fs--2 my-0'>{errors.name.message}</p>
        )}
        <Form.Control
          type='email'
          size='md'
          placeholder='Email'
          {...register('email')}
        />
        {errors.email && (
          <p className='text-danger fs--2 my-0'>{errors.email.message}</p>
        )}
        <div style={{ height: '0px', overflow: 'hidden' }}>
          <Form.Control
            type='text'
            size='md'
            placeholder='Message'
            disabled
            {...register('message')}
          />
        </div>
        <div className='mt-3'>
          <Button
            type='submit'
            variant='primary'
            size='md'
            disabled={submitLoading || !name || !email}
          >
            {`Send`}
          </Button>
        </div>
      </form>
    </Fragment>
  );
};

MailingListForm.propTypes = {
  contactUs_Action: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { contactUs_Action })(MailingListForm);

