import React from 'react';
import { Image } from 'react-bootstrap';

import PropTypes from 'prop-types';

import { LOGOS } from 'blockscope/static/logoChains';
import { CLEAN_CHAIN_NAMES } from 'blockscope/static/supportedChains';

import ExplainerV2 from 'blockscope/components/blockscopeCommon/Tooltip';

const ChainLogo = ({ height = 25, chain }) => {
  return (
    <ExplainerV2
      content={
        <span className='text-capitalize'>{CLEAN_CHAIN_NAMES[chain]}</span>
      }
    >
      <Image src={LOGOS[chain]} height={height} alt={chain} className='me-1' />
    </ExplainerV2>
  );
};

ChainLogo.propTypes = {
  height: PropTypes.number,
  chain: PropTypes.string.isRequired,
};

export default ChainLogo;

