export const NOTIFICATION_TYPES = {
  // Watchtowers
  WATCHTOWER_CREATED: 'watchtower_created',
  WATCHTOWER_DELETED: 'watchtower_deleted',

  // Pipelines
  PIPELINE_CREATED: 'pipeline_created',
  PIPELINE_DELETED: 'pipeline_deleted',

  // User
  USER_JOINED_ORGANIZATION: 'user_joined_organization',
  USER_PASSWORD_CHANGED: 'user_password_changed',
};
