import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import HoverableIcon from 'blockscope/components/blockscopeCommon/HoverableIcon';

const ExplainerModal = ({ explain: { title, heading, description } }) => {
  const [showModal, setShowModal] = useState(false);

  const toggle = () => {
    setShowModal(!showModal);
  };

  return (
    <Fragment>
      <HoverableIcon
        icon={faInfoCircle}
        onClick={() => toggle()}
        className='fs--2 px-1'
        size='xs'
      />
      <Modal show={showModal} onHide={() => toggle()}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=''>
          <Alert variant={'info'}>
            <div className='alert-message'>
              <strong>{heading}</strong>
              <br></br>
              {description}
            </div>
          </Alert>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

const Explainer = ({
  text,
  anchorId,
  children,
  place = 'top',
  hideTooltip,
}) => {
  const [id, setId] = useState();

  useEffect(() => {
    setId(anchorId || Math.random().toString());
  }, [anchorId]);
  return (
    <Fragment>
      <span
        data-tooltip-id={id}
        data-tooltip-content={!hideTooltip ? text : null}
        data-tooltip-place={place}
      >
        {children || (
          <HoverableIcon icon={faInfoCircle} className='fs--2 px-1' size='xs' />
        )}
      </span>
      <Tooltip id={id} className='fs--2' style={{ zIndex: 99999999 }} />
    </Fragment>
  );
};

ExplainerModal.propTypes = {
  explain: PropTypes.shape({
    title: PropTypes.string,
    heading: PropTypes.string,
    description: PropTypes.string,
  }),
};

Explainer.propTypes = {
  anchorId: PropTypes.string,
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
  place: PropTypes.string,
  hideTooltip: PropTypes.bool,
};
Explainer.Modal = ExplainerModal;

export default Explainer;

