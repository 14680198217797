import React, { Fragment, useState } from 'react';
import { Button, Dropdown, Form, InputGroup } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { ControlledChainSelector } from './ChainSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { usePostHog } from 'posthog-js/react';
import PropTypes from 'prop-types';
import { z } from 'zod';

import { ORG_SERVICES } from 'blockscope/static/blockscopeServices';
import chainData from 'blockscope/static/chainData';
import {
  BLOCK_BREAKDOWN_POSTHOG,
  TXN_DECODER_POSTHOG,
  WALLET_ACTIVITY_POSTHOG,
} from 'blockscope/static/posthogEvents';
import { QUERY_TYPE_LINKS } from 'blockscope/static/queryTypes';
import {
  CHAINS,
  CHAINS_OPTIONS_LIST,
  CLEAN_CHAIN_NAMES,
  SERVICE_CHAINS,
} from 'blockscope/static/supportedChains';

import { isIterableArray } from 'blockscope/helpers/utils';

import Flex from 'blockscope/components/common/Flex';

const examples = [
  {
    id: 0,
    breadCrumbTexts: ['Transaction', 'Uniswap Swap'],
    url: '/transaction-decoder/ethereum/0x8b75c87493623291d34277545cc2bf003cd86e683f652a2605100b3148354aff',
  },
  {
    id: 1,
    breadCrumbTexts: ['Transaction', 'Flash Loan Attack'],
    url: '/transaction-decoder/ethereum/0xb5c8bd9430b6cc87a0e2fe110ece6bf527fa4f170a4bc8cd032f768fc5219838',
  },
  {
    id: 0,
    breadCrumbTexts: ['Contract', 'UNI Token'],
    url: '/contract-usage/ethereum/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984/?startTime=2023-01-01&endTime=2023-01-31',
  },
  {
    id: 1,
    breadCrumbTexts: ['Contract', 'BAYC NFT Collection'],
    url: '/contract-usage/ethereum/0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D/?startTime=2023-01-01&endTime=2023-01-31',
  },
  {
    id: 0,
    breadCrumbTexts: ['Block', '#17692907'],
    url: '/block-breakdown/ethereum/17692907',
  },
];

const searchSchema = z
  .object({
    chain: z.enum(SERVICE_CHAINS.ALL),
    searchText: z.union([
      chainData.evmTransactionHashSchema,
      chainData.evmAddressSchema,
      z.preprocess((v) => Number(v), z.number().positive()),
    ]),
  })
  .transform((val) => {
    let service = null;
    if (chainData.evmTransactionHashSchema.safeParse(val.searchText).success) {
      service = ORG_SERVICES.TRANSACTION_DECODER;
    } else if (chainData.evmAddressSchema.safeParse(val.searchText).success) {
      service = ORG_SERVICES.ADDRESS_ACTIVITY;
    } else if (typeof val.searchText === 'number') {
      service = ORG_SERVICES.BLOCK_BREAKDOWN;
    }
    return { ...val, service };
  })
  .superRefine((val, ctx) => {
    const { chain, service } = val;
    if (service === null) {
      ctx.addIssue({
        message: 'Invalid Search',
        code: z.ZodIssueCode.custom,
        path: ['service'],
      });
    }
    if (
      service === ORG_SERVICES.TRANSACTION_DECODER &&
      !SERVICE_CHAINS[ORG_SERVICES.TRANSACTION_DECODER].includes(chain)
    ) {
      ctx.addIssue({
        message: `Transaction Decoder is not supported for ${CLEAN_CHAIN_NAMES[chain]}`,
        code: z.ZodIssueCode.custom,
        path: ['searchText'],
      });
    } else if (
      service === ORG_SERVICES.ADDRESS_ACTIVITY &&
      !SERVICE_CHAINS[ORG_SERVICES.ADDRESS_ACTIVITY].includes(chain)
    ) {
      ctx.addIssue({
        message: `Wallet Profiler is not supported for ${CLEAN_CHAIN_NAMES[chain]}`,
        code: z.ZodIssueCode.custom,
        path: ['searchText'],
      });
    } else if (
      service === ORG_SERVICES.BLOCK_BREAKDOWN &&
      !SERVICE_CHAINS[ORG_SERVICES.BLOCK_BREAKDOWN].includes(chain)
    ) {
      ctx.addIssue({
        message: `Block Breakdown is not supported for ${CLEAN_CHAIN_NAMES[chain]}`,
        code: z.ZodIssueCode.custom,
        path: ['searchText'],
      });
    }
  });
const SearchBox = ({
  autoCompleteItem,

  width = '500px',
  autocomplete = true,
}) => {
  const posthog = usePostHog();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [resultItem] = useState(autoCompleteItem);
  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      chain: CHAINS.ETHEREUM,
    },
    resolver: zodResolver(searchSchema),
  });

  const executeSearch = (data) => {
    const { chain, service, searchText } = data;

    let posthogId = '';
    let posthogObject = {};
    let url = '';

    if (service === ORG_SERVICES.TRANSACTION_DECODER) {
      // Valid transaction hash
      url = QUERY_TYPE_LINKS[ORG_SERVICES.TRANSACTION_DECODER]({
        chain,
        transactionHash: searchText,
      });
      posthogId = TXN_DECODER_POSTHOG.SEARCH;
      posthogObject = { txHash: searchText, chain };
    } else if (service === ORG_SERVICES.ADDRESS_ACTIVITY) {
      // Valid Ethereum address
      url = QUERY_TYPE_LINKS[ORG_SERVICES.ADDRESS_ACTIVITY]({
        chain,
        address: searchText,
      });
      posthogId = WALLET_ACTIVITY_POSTHOG.SEARCH;
      posthogObject = {
        address: searchText,
        chain,
      };
    } else if (service === ORG_SERVICES.BLOCK_BREAKDOWN) {
      // Valid block number
      url = QUERY_TYPE_LINKS[ORG_SERVICES.BLOCK_BREAKDOWN]({
        chain,
        blockNumber: searchText,
      });
      posthogId = BLOCK_BREAKDOWN_POSTHOG.SEARCH;
      posthogObject = {
        blockNumber: searchText, // get from form
        chain,
      };
    }

    if (url) {
      window.location.href = url;
      posthog.capture(posthogId, posthogObject);

      setDropdownOpen(false);
    } else {
      return;
    }
  };

  return (
    <Fragment>
      <div className='d-flex align-items-center'>
        <div className='me-1'>
          <ControlledChainSelector
            control={control}
            chainOptions={CHAINS_OPTIONS_LIST(SERVICE_CHAINS.ALL)}
          />
        </div>
        <InputGroup>
          <div
            style={{
              alignSelf: 'flex-start',
            }}
          >
            <Dropdown
              onToggle={setDropdownOpen}
              // className='search-box'
              style={{ width }}
              align={'start'}
            >
              <Dropdown.Toggle
                as='div'
                // data-toggle='dropdown'
                aria-expanded={dropdownOpen}
                bsPrefix='toggle'
              >
                <Controller
                  name='searchText'
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      placeholder='Search Services/Transactions/Addresses/Blocks'
                      aria-label='Search'
                      value={field.value}
                      onChange={({ target }) => field.onChange(target.value)}
                      onClick={() => setDropdownOpen(false)}
                      style={{ width }}
                    />
                  )}
                />
              </Dropdown.Toggle>
              {autocomplete && (
                <Dropdown.Menu style={{ marginLeft: '63px' }}>
                  <div
                    className='scrollbar py-3'
                    style={{ maxHeight: '24rem', width }}
                  >
                    {isIterableArray(examples) && (
                      <>
                        {examples.length > 0 && (
                          <Dropdown.Header
                            as='h6'
                            className='px-card pt-0 pb-2 fw-medium'
                          >
                            Examples
                          </Dropdown.Header>
                        )}
                        {examples.map((item) => (
                          <Dropdown.Item
                            as={Link}
                            to={item.url}
                            className='fs--1 px-card py-1 hover-primary'
                            key={item.url}
                          >
                            <Flex alignItems='center'>
                              <FontAwesomeIcon
                                icon='circle'
                                className='me-2 text-300 fs--2'
                              />
                              <div className='fw-normal'>
                                {item.breadCrumbTexts.map(
                                  (breadCrumbText, index) => (
                                    <Fragment key={breadCrumbText}>
                                      {breadCrumbText}
                                      {item.breadCrumbTexts.length - 1 >
                                        index && (
                                        <FontAwesomeIcon
                                          icon='chevron-right'
                                          className='mx-1 text-500 fs--2'
                                          transform='shrink 2'
                                        />
                                      )}
                                    </Fragment>
                                  )
                                )}
                              </div>
                            </Flex>
                          </Dropdown.Item>
                        ))}
                      </>
                    )}

                    {resultItem && resultItem.length === 0 && (
                      <p className='fs-1 fw-bold text-center mb-0'>
                        No Result Found.
                      </p>
                    )}
                  </div>
                </Dropdown.Menu>
              )}
            </Dropdown>
          </div>
          <Button
            onClick={handleSubmit(executeSearch)}
            style={{ height: '36px' }}
          >
            <FontAwesomeIcon icon='magnifying-glass' className=' py-0' />
          </Button>
        </InputGroup>
      </div>
      <div>
        {errors?.searchText ? (
          <p className='text-danger fs--2 mb-0'>{errors.searchText.message}</p>
        ) : null}
      </div>
    </Fragment>
  );
};
SearchBox.propTypes = {
  autoCompleteItem: PropTypes.object,

  width: PropTypes.string,
  autocomplete: PropTypes.bool,
};

export default SearchBox;

