import { GUESS_ABI, SET_GUESS_ABI_LOADER, LOGOUT } from '../types';

const initialState = {
  abiGuesserSearchResults: {},
  abiGuesserLoader: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_GUESS_ABI_LOADER:
      return {
        ...state,
        abiGuesserLoader: payload.loadingStatus,
      };

    case GUESS_ABI:
      return {
        ...state,
        abiGuesserSearchResults: payload,
      };
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}

