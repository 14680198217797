import { API_CALL_METHODS } from 'blockscope/components/documentation/developers/configs/code';
import { useDocumentStore } from 'blockscope/components/documentation/developers/state/documentStore';

function paramFunction(paramArray) {
  let paramStrings = [];
  let parameterCount = paramArray.length;
  paramArray.forEach((param, index) => {
    paramStrings.push(
      `${index + 1 === parameterCount ? '\n  ' : '  '}"${param.name}": ${
        param.type === 'string array' ? '[\n    ' : ''
      }"${param.value}"${param.type === 'string array' ? '\n  ]' : ''}`
    );
  });
  return `${paramStrings.map((string) => {
    return string;
  })}`;
}

export function createCurlCodeSnippet(
  callMethod = API_CALL_METHODS.GET,
  apiUrl = 'http://www.example.com/api/'
) {
  const apiKey = useDocumentStore((state) => state.apiKeyState);
  const parameterState = useDocumentStore((state) => state.parameterState);
  return `curl --request ${callMethod.value} ${'\\'} 
     --url ${apiUrl} ${'\\'} 
     --header 'accept: application/json' ${'\\'} 
     --header 'content-type: application/json' ${'\\'} 
     --header 'X-API-KEY: ${apiKey}' ${'\\'}  
     --data '
{
${paramFunction(parameterState)}
}
'`;
}
