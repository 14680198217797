export const productFooterList = [
  { title: 'Studio', to: '/solutions/studio' },
  { title: 'Defender', to: '/solutions/defender' },
  { title: 'Devkit', to: '/solutions/devkit' },
];

export const companyFooterList = [
  { title: 'About', to: '/company' },
  { title: 'Contact', to: '/contact-us' },
  { title: 'Privacy', to: '/privacy-policy' },
  { title: 'Blog', to: '/blog' },
  { title: 'Pricing', to: '/pricing' },
  { title: 'Terms and Conditions', to: '/tos' },
];
