import curlIcon from 'blockscope/assets/svgRepo/curl-svgrepo.svg';
import javascriptIcon from 'blockscope/assets/svgRepo/javascript-svgrepo.svg';
import pythonIcon from 'blockscope/assets/svgRepo/python-svgrepo.svg';

import { createAxiosCodeSnippet } from 'blockscope/components/documentation/developers/utils/codeSnippetFunctions/createAxiosCodeSnippet';
import { createCurlCodeSnippet } from 'blockscope/components/documentation/developers/utils/codeSnippetFunctions/createCurlCodeSnippet';
import { createFetchCodeSnippet } from 'blockscope/components/documentation/developers/utils/codeSnippetFunctions/createFetchCodeSnippet';
import { createHttpieCodeSnippet } from 'blockscope/components/documentation/developers/utils/codeSnippetFunctions/createHttpieCodeSnippet';
import { createJqueryCodeSnippet } from 'blockscope/components/documentation/developers/utils/codeSnippetFunctions/createJqueryCodeSnippet';
import { createPythonCodeSnippet } from 'blockscope/components/documentation/developers/utils/codeSnippetFunctions/createPythonCodeSnippet';
import { createXmlCodeSnippet } from 'blockscope/components/documentation/developers/utils/codeSnippetFunctions/createXmlCodeSnippet';

export const LANGUAGES = {
  CURL: 'curl',
  JAVASCRIPT: 'javascript',
  PYTHON: 'python',
};
export const LANGUAGE_OPTIONS = [
  { value: LANGUAGES.CURL, label: 'Curl', icon: curlIcon },
  { value: LANGUAGES.JAVASCRIPT, label: 'JavaScript', icon: javascriptIcon },
  { value: LANGUAGES.PYTHON, label: 'Python', icon: pythonIcon },
];

export const LIBRARIES = {
  // CURL LIBRARIES
  CURL: 'curl',
  HTTPIE: 'httpie',
  // JAVASCRIPT LIBRARIES
  AXIOS: 'axios',
  FETCH: 'fetch',
  JQUERY: 'jquery',
  XMLHTTPREQUEST: 'xmlhttprequest',
  // PYTHON
  PYTHON: 'python',
};

export const LANGUAGE_TO_LIBRARY_OPTIONS = {
  [LANGUAGES.CURL]: [
    { value: LIBRARIES.CURL, label: 'cURL' },
    {
      value: LIBRARIES.HTTPIE,
      label: 'HTTPie',
      installationString: `brew install httpie`,
    },
  ],
  [LANGUAGES.JAVASCRIPT]: [
    {
      value: LIBRARIES.AXIOS,
      label: 'Axios',
      installationString: `npm install axios --save`,
    },
    { value: LIBRARIES.FETCH, label: 'Fetch' },
    { value: LIBRARIES.JQUERY, label: 'jQuery' },
    { value: LIBRARIES.XMLHTTPREQUEST, label: 'XMLHttpRequest ' },
  ],
  [LANGUAGES.PYTHON]: [
    {
      value: LIBRARIES.PYTHON,
      label: 'Python',
      installationString: `python -m pip install requests`,
    },
  ],
};

export const API_CALL_METHODS = {
  GET: { value: 'GET', badgeColor: '#01D27B' },
  POST: { value: 'POST', badgeColor: '#2B7CE4' },
  PUT: { value: 'PUT', badgeColor: '#2B7CE4' },
  PATCH: { value: 'PATCH', badgeColor: '#2B7CE4' },
  DELETE: { value: 'DELETE', badgeColor: '#E63757' },
  COPY: { value: 'COPY', badgeColor: '#F6A071' },
  HEAD: { value: 'HEAD', badgeColor: '#27BCFD' },
  OPTIONS: { value: 'OPTIONS', badgeColor: '#27BCFD' },
  LINK: { value: 'LINK', badgeColor: '#F6A071' },
  UNLINK: { value: 'UNLINK', badgeColor: '#F6A071' },
  PURGE: { value: 'PURGE', badgeColor: '#F6A071' },
  LOCK: { value: 'LOCK', badgeColor: '#F6A071' },
  UNLOCK: { value: 'UNLOCK', badgeColor: '#F6A071' },
  PROPFIND: { value: 'PROPFIND', badgeColor: '#F6A071' },
  VIEW: { value: 'VIEW', badgeColor: '#F6A071' },
};

export const CODE_SNIPPET_FUNCTIONS = {
  curl: createCurlCodeSnippet,
  httpie: createHttpieCodeSnippet,
  axios: createAxiosCodeSnippet,
  fetch: createFetchCodeSnippet,
  jquery: createJqueryCodeSnippet,
  xmlhttprequest: createXmlCodeSnippet,
  python: createPythonCodeSnippet,
};
