import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { queryClient } from 'stateManagement/queries/client';

import { NOTIFICATION_TOAST_ID } from 'blockscope/static/toastIds';

import { axiosErrorToast } from 'blockscope/utils/toastHandler';

const NOTIFICATION_QUERY_LIMIT = 100;

const minutesToMiliseconds = (minutes) => {
  return minutes * 60 * 1000;
};

const getNotificationsEndpoint = async () => {
  const res = await axios.get(
    `/api/v2/notifications?sortorder=-1&limit=${NOTIFICATION_QUERY_LIMIT}&page=1&sortby=_id`
  );
  return res.data.dataPayload.notifications || [];
};

export const useGetNotifications_Query = () => {
  return useQuery({
    queryKey: ['notifications'],
    queryFn: () => getNotificationsEndpoint(),
    cacheTime: minutesToMiliseconds(10),
    staleTime: minutesToMiliseconds(20),
    retry: false,
    onError: (err) =>
      axiosErrorToast(err, NOTIFICATION_TOAST_ID.ERROR.GET_NOTIFICATIONS),
  });
};

export const useMarkNotificationAsRead_Mutation = () => {
  return useMutation({
    mutationFn: async (inputData) => {
      const res = await axios.put(`/api/v2/notifications/read`, inputData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return res.data.message;
    },
    onSuccess: async (data, inputData) => {
      await queryClient.cancelQueries(['notifications']);

      const oldNotifications = queryClient.getQueryData(['notifications']);

      let newNotifications = oldNotifications;
      newNotifications.forEach((notification) => {
        if (notification._id === inputData.notificationIds[0]) {
          notification.status = 'read';
        }
      });

      queryClient.setQueryData(['notifications'], (oldData) =>
        oldData ? [...newNotifications] : oldData
      );
    },
    onError: (err) => {
      axiosErrorToast(err, NOTIFICATION_TOAST_ID.ERROR.MARK_AS_READ);
    },
  });
};

export const useMarkAllNotificationsAsRead_Mutation = () => {
  return useMutation({
    mutationFn: async () => {
      const res = await axios.put(
        `/api/v2/notifications/read/all`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return res.data.message;
    },
    onSuccess: async () => {
      await queryClient.cancelQueries(['notifications']);

      const oldNotifications = queryClient.getQueryData(['notifications']);

      let newNotifications = oldNotifications;
      newNotifications.forEach((notification) => {
        notification.status = 'read';
      });

      queryClient.setQueryData(['notifications'], (oldData) =>
        oldData ? [...newNotifications] : oldData
      );
    },
    onError: (err) => {
      axiosErrorToast(err, NOTIFICATION_TOAST_ID.ERROR.MARK_ALL_AS_READ);
    },
  });
};
