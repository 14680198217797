import { SERVICE_ICON_PATHS } from 'blockscope/components/common/BlockscopeServiceIcon';

export const productData = [
  {
    title: 'Watchtower',
    description:
      'No-code tool to monitor, capture and send alerts based on specific on-chain activity.',
    img: SERVICE_ICON_PATHS.WATCHTOWER,
    link: '/products/watchtower',
    toolkits: ['Monitoring Toolkit'],
  },
  {
    title: 'Pipelines',
    description:
      'Send on-chain data to your database in real time. We manage the indexing infra!',
    img: SERVICE_ICON_PATHS.PIPELINES,
    link: '/products/pipelines',
    toolkits: ['Dev Toolkit'],
  },
  {
    title: 'Tracer',
    description: 'Trace funds moving across wallets, contracts and chains.',
    img: SERVICE_ICON_PATHS.TRACER,
    link: '/products/tracer',
    toolkits: ['Forensics Toolkit'],
  },
  {
    title: 'Transaction Decoder (KYT)',
    description:
      'All actions involved in a single transaction, decoded & readable.',
    img: SERVICE_ICON_PATHS.TRANSACTION_DECODER,
    link: '/products/transaction-decoder',
    toolkits: ['Observability Toolkit'],
  },
  {
    title: 'Wallet Profile (KYC)',
    description:
      'Everything you need to know about a wallet and its activity on-chain.',
    img: SERVICE_ICON_PATHS.WALLET_PROFILER,
    link: '/products/wallet-profiler',
    toolkits: ['Observability Toolkit'],
  },
  {
    title: 'Investigator',
    description:
      'Sleuth and save your findings for your on going investigations.',
    img: SERVICE_ICON_PATHS.INVESTIGATOR,
    link: '/products/investigator',
    toolkits: ['Forensics Toolkit'],
  },
  {
    title: 'Contract Usage',
    description:
      'Quickly search and get usage and daily activity data of any contract or protocol.',
    img: SERVICE_ICON_PATHS.CONTRACT_USAGE,
    link: '/products/contract-usage',
    toolkits: ['Analytics Toolkit'],
  },
  {
    title: 'Block Breakdown',
    description:
      'Indepth breakdown of what happened in any block you search for.',
    img: SERVICE_ICON_PATHS.BLOCK_BREAKDOWN,
    link: '/products/block-breakdown',
    toolkits: ['Observability Toolkit'],
  },
  {
    title: 'Web3 Directories',
    description:
      'Index Data Directories of common entities, contracts, events, functions for easy access to on-chain data.',
    img: SERVICE_ICON_PATHS.WEB_DIRECTORY,
    link: '/products/web-directory',
    toolkits: ['Analytics Toolkit'],
  },
  {
    title: 'Security Monitoring',
    description:
      'Create and implement custom risk rules to analyze and monitor smart contracts for vulnerabilities and compliance.',
    img: SERVICE_ICON_PATHS.SECURITY_MONITOR,
    link: '/products/contract-guard',
    toolkits: ['Analytics Toolkit'],
  },
  {
    title: 'API Services',
    description: 'Key blockchain data primitives at your fingertips.',
    img: SERVICE_ICON_PATHS.API_SERVICES,
    link: '/products/api-services',
    toolkits: ['Dev Toolkit'],
  },
];

