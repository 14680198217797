import React, { Fragment, useEffect, useState } from 'react';
import { Card, Dropdown, ListGroup, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import {
  useGetNotifications_Query,
  useMarkAllNotificationsAsRead_Mutation,
  useMarkNotificationAsRead_Mutation,
} from 'stateManagement/queries/services/notificationQueries';

import { isIterableArray } from 'blockscope/helpers/utils';

import FalconCardHeader from 'blockscope/components/common/FalconCardHeader';

import Notification from 'blockscope/components/notification/Notification';
import NotificationContent from 'blockscope/components/notification/NotificationContent';

const NotificationDropdown = () => {
  const { data: notificationsData } = useGetNotifications_Query();
  const { mutate: markNotificationAsRead } =
    useMarkNotificationAsRead_Mutation();

  const { mutate: markAllNotificationsAsRead } =
    useMarkAllNotificationsAsRead_Mutation();

  // State
  const [isOpen, setIsOpen] = useState(false);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  return (
    <Fragment>
      {notificationsData === undefined ? (
        <div className='text-center'>
          <div className='text-center'>
            <Spinner color='primary' size='sm' />
          </div>
        </div>
      ) : (
        <Dropdown navbar={true} as='li' show={isOpen} onToggle={handleToggle}>
          <Dropdown.Toggle
            bsPrefix='toggle'
            as={Link}
            to='#!'
            className={classNames('px-0 nav-link', {
              'notification-indicator notification-indicator-primary':
                notificationsData.some((noti) => noti.status === 'unread'),
            })}
          >
            <FontAwesomeIcon
              icon='bell'
              transform='shrink-6'
              className='fs-4'
            />
          </Dropdown.Toggle>

          <Dropdown.Menu className='dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg'>
            <Card
              className='dropdown-menu-notification dropdown-menu-end shadow-none'
              style={{ maxWidth: '20rem' }}
            >
              <FalconCardHeader
                className='card-header'
                title='Notifications'
                titleTag='h6'
                light={false}
                endEl={
                  <Link
                    className='card-link fw-normal'
                    to='#!'
                    onClick={() => {
                      markAllNotificationsAsRead();
                    }}
                  >
                    Mark all as read
                  </Link>
                }
              />
              <ListGroup
                variant='flush'
                className='fw-normal fs--1 scrollbar'
                style={{ maxHeight: '19rem' }}
              >
                {isIterableArray(notificationsData) &&
                  notificationsData.slice(0, 5).map((notification) => (
                    <ListGroup.Item
                      key={notification._id}
                      onClick={() => {
                        markNotificationAsRead({
                          notificationIds: [notification._id],
                        });
                      }}
                    >
                      {(notification?.data?.triggerUser?.name ||
                        notification?.data?.name ||
                        notification?.data?.userName) && (
                        <Notification
                          className='rounded-0 border-x-0 border-300 border-bottom-0'
                          unread={notification.status === 'unread'}
                          time={notification.createdAt}
                          flush
                          avatar={{
                            name:
                              notification?.data?.triggerUser?.name ||
                              notification?.data?.name ||
                              notification?.data?.userName,
                            size: '2xl',
                          }}
                        >
                          <NotificationContent {...notification} />
                        </Notification>
                      )}
                    </ListGroup.Item>
                  ))}
              </ListGroup>
              <div
                className='card-footer text-center border-top'
                onClick={handleToggle}
              >
                <Link className='card-link d-block' to='/my-notifications'>
                  View all
                </Link>
              </div>
            </Card>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Fragment>
  );
};

export default NotificationDropdown;

