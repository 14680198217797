import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { TallyMailIllustration } from 'blockscope/assets/freepik/MailingList';

import MailingListForm from '../../../../../components/pages/landing/MailingListForm';

const MailingListSection = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://tally.so/widgets/embed.js';
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <section
        className='py-4'
        style={{ backgroundColor: '#fff', border: 'solid 0px black' }}
      >
        <Container>
          <Row className='justify-content-md-center mb-2'>
            <Col xs='12' sm='6' lg='4'>
              <TallyMailIllustration style={{ maxHeight: '250px' }} />
            </Col>
            <Col xs='12' sm='6' lg='8'>
              <div
                style={{
                  marginLeft: '8px',
                  marginTop: '16px',
                  marginBottom: '16px',
                }}
              >
                <h4>Join our mailing list and get updates from Blockscope</h4>
              </div>

              <div
                style={{
                  marginLeft: '8px',
                  marginRight: '8px',
                  marginTop: '16px',
                  marginBottom: '16px',
                }}
              >
                <MailingListForm />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MailingListSection;
