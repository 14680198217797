import React, { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, NavItem } from 'reactstrap';

// other
import AppContext from 'context/Context';

// components
// import Registration from '../../authentication/simple/SimpleRegistrationPage';

const LandingRightSideNavItem = ({
  //reducers
  isAuthenticated,
}) => {
  // const [showRegistrationModal, setShowRegistrationModal] = useState(false);

  const {
    config: { isDark, isRTL },
    setConfig,
  } = useContext(AppContext);

  return (
    <Nav navbar className='ms-auto'>
      {/* <Nav.Item as={'li'}>
        <Nav.Link
          className='pe-2 theme-switch-toggle'
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key='left'
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip id='ThemeColor' className='d-none d-lg-block'>
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
            <span>
              <div className='theme-switch-toggle-label'>
                <FontAwesomeIcon
                  icon={isDark ? 'sun' : 'moon'}
                  className='me-2'
                />
              </div>
              <p className='d-lg-none mb-0'>
                {isDark ? 'Switch to light theme ' : 'Switch to dark theme'}
              </p>
            </span>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item> */}

      <NavItem className='me-2'>
        <Button
          tag={Link}
          to={`/login`}
          color='light'
          className='fs--1 border-1x rounded-pill mr-2 text-dark mb-2 mb-lg-0'
        >
          {isAuthenticated === true ? `Back to Dashboard` : `Log in`}
        </Button>
      </NavItem>

      {isAuthenticated !== true ? (
        <NavItem className='me-2'>
          <Button
            tag={Link}
            to={`/signup?from=demo`}
            color='success'
            className='fs--1 border-1x rounded-pill text-dark'
          >
            {isAuthenticated === true ? `Back to Dashboard` : `Sign Up`}
          </Button>
        </NavItem>
      ) : null}

      {/* {isAuthenticated !== true ? (
        <NavItem>
          <Button
            color='success'
            className='fs--1 border-1x rounded-pill text-dark'
            onClick={() => setShowRegistrationModal(!showRegistrationModal)}
          >
            Sign Up
          </Button>

          <Modal
            isOpen={showRegistrationModal}
            centered
            toggle={() => setShowRegistrationModal(!showRegistrationModal)}
          >
            <ModalBody className='p-0'>
              <Card>
                <CardBody className='fs--1 font-weight-normal p-4'>
                  <Registration />
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        </NavItem>
      ) : null} */}
    </Nav>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
});
export default connect(mapStateToProps, {})(LandingRightSideNavItem);

