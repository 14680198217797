import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ctaBG from 'blockscope/assets/landingBackgrounds/darkbg.jpg';

import Section from 'blockscope/components/common/Section';

const Cta = () => (
  <Section
    overlay
    image={ctaBG}
    position='center top'
    className='light bg-dark'
  >
    <Row className='justify-content-center text-center'>
      <Col lg={8}>
        <p className='fs-3 fs-sm-4 text-white'>
          Join other <b>crypto users, trading firms, web3 startups </b>
          and <b>institutions</b> using Blockscope right now!
        </p>
        <Link to='/signup?from=learnmore'>
          <Button
            variant='outline-light'
            size='lg'
            className='border-2 rounded-pill mt-4 fs-0 py-2'
          >
            Learn More
          </Button>
        </Link>
      </Col>
    </Row>
  </Section>
);

export default Cta;

