import {
  GET_ORG_DETAILS,
  GET_ORG_INVITED_USERS,
  LOGOUT,
  SET_ORG_DETAILS_LOADER,
  SET_ORG_INVITED_USERS_LOADER,
} from '../types';

const initialState = {
  orgId: null,
  orgDetails: null,
  orgDetailsLoader: false,

  orgInvitedUsers: [],
  orgInvitedUsersLoader: false,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ORG_DETAILS_LOADER:
      return {
        ...state,
        orgDetailsLoader: payload.loadingStatus,
      };

    case GET_ORG_DETAILS:
      return {
        ...state,
        orgDetails: payload,
        orgId: payload?.org?._id,
      };

    case SET_ORG_INVITED_USERS_LOADER:
      return {
        ...state,
        orgInvitedUsersLoader: payload.loadingStatus,
      };

    case GET_ORG_INVITED_USERS:
      return {
        ...state,
        orgInvitedUsers: payload.invites,
      };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}

