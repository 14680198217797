import React from 'react';

import PropTypes from 'prop-types';

import apiServices from 'blockscope/assets/serviceIcons/api-services.svg';
import blockBreakdown from 'blockscope/assets/serviceIcons/block-breakdown.svg';
import contractBreakdown from 'blockscope/assets/serviceIcons/contract-breakdown.svg';
import contractUsage from 'blockscope/assets/serviceIcons/contract-usage.svg';
import entityInteraction from 'blockscope/assets/serviceIcons/entity-interaction.svg';
import directory from 'blockscope/assets/serviceIcons/evm-directory.svg';
import investigator from 'blockscope/assets/serviceIcons/investigator.svg';
import labels from 'blockscope/assets/serviceIcons/labels.svg';
import memos from 'blockscope/assets/serviceIcons/memos.svg';
import pipelines from 'blockscope/assets/serviceIcons/pipelines.svg';
import securityMonitor from 'blockscope/assets/serviceIcons/security-monitor.svg';
import tracer from 'blockscope/assets/serviceIcons/tracer.svg';
import transactionDecoder from 'blockscope/assets/serviceIcons/transaction-decoder.svg';
import walletProfiler from 'blockscope/assets/serviceIcons/wallet-profiler.svg';
import watchtower from 'blockscope/assets/serviceIcons/watchtower.svg';
import webDirectory from 'blockscope/assets/serviceIcons/web-directory.svg';
import webHacks from 'blockscope/assets/serviceIcons/web-hacks.svg';
import webhooks from 'blockscope/assets/serviceIcons/webhooks.svg';

export const SERVICE_ICON_PATHS = {
  BLOCK_BREAKDOWN: blockBreakdown,
  CONTRACT_USAGE: contractUsage,
  INVESTIGATOR: investigator,
  LABELS: labels,
  MEMOS: memos,
  PIPELINES: pipelines,
  SECURITY_MONITOR: securityMonitor,
  TRACER: tracer,
  TRANSACTION_DECODER: transactionDecoder,
  TRANSACTION_SIMULATOR: transactionDecoder,
  WALLET_PROFILER: walletProfiler,
  WATCHTOWER: watchtower,
  WEB_HACKS: webHacks,
  WEB_DIRECTORY: webDirectory,
  WEB_HOOKS: webhooks,
  API_SERVICES: apiServices,
  CONTRACT_BREAKDOWN: contractBreakdown,
  EVM_EVENT_DIRECTORY: directory,
  EVM_FUNCTION_DIRECTORY: directory,
  ENTITY_INTERACTION: entityInteraction,
};

const ServiceIcon = ({ svg, width = '32px' }) => {
  return (
    <div style={{ width: width }}>
      <img src={svg} className='w-100' />
    </div>
  );
};

ServiceIcon.propTypes = {
  svg: PropTypes.oneOf(
    Object.keys(SERVICE_ICON_PATHS).map((key) => SERVICE_ICON_PATHS[key])
  ).isRequired,
  width: PropTypes.string,
};

export default ServiceIcon;

