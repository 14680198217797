import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

import PropTypes from 'prop-types';

import cookieLogo from 'blockscope/assets/cookies.svg';

import CookieNotice from 'blockscope/components/common/CookieNotice';
import Flex from 'blockscope/components/common/Flex';

import FooterStandard from 'blockscope/components/pages/landing/components/FooterStandard';
import NavbarStandard from 'blockscope/components/pages/landing/components/NavbarStandard';

const SolutionsLayout = () => {
  window.scrollTo(0, 0);
  const [showCookieNotice, setShowCookieNotice] = useState(
    localStorage.getItem('BlockscopeCookiesAllowed') !== 'true'
  );

  return (
    <>
      <NavbarStandard />

      <Outlet />

      <FooterStandard />
      <CookieNotice
        show={showCookieNotice}
        delay={3000}
        setShow={setShowCookieNotice}
      >
        <Card>
          <Card.Body>
            <Flex>
              <div className='pe-3'>
                <img src={cookieLogo} alt='cookie' width={40} />
              </div>
              <div className=''>
                <p>We use third party cookies to analyze site traffic.</p>
                <Button
                  size='sm'
                  variant='falcon-primary'
                  className='me-3'
                  onClick={() => {
                    setShowCookieNotice(!showCookieNotice);
                    localStorage.setItem('BlockscopeCookiesAllowed', true);
                  }}
                >
                  Okay
                </Button>
              </div>
            </Flex>
          </Card.Body>
        </Card>
      </CookieNotice>
    </>
  );
};

SolutionsLayout.propTypes = {
  match: PropTypes.object,
};

export default SolutionsLayout;
