import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';

// components
import Flex from '../../../../blockscope/components/common/Flex';
import LoginForm from '../../../../blockscope/components/authentication/LoginForm';

// actions
import { logout_Action } from '../../../../stateManagement/redux/actions/authActions';
import { SET_CONFIRM_EMAIL } from 'stateManagement/redux/types';

const ConfirmEmailPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout_Action());
    dispatch({
      type: SET_CONFIRM_EMAIL,
      payload: { confirmEmail: true },
    });
  }, []);

  return (
    <>
      <h4>Confirm Email</h4>
      {/* <Flex justifyContent='between' alignItems='center' className='mb-2'> */}
      <Alert variant='warning' className='py-2 ps-3 mb-4 text-dark'>
        Please sign in to confirm your email.
      </Alert>
      {/* </Flex> */}
      <hr></hr>
      <LoginForm />
    </>
  );
};

export default ConfirmEmailPage;
