import axios from 'axios';

import { OTHER_ACTIONS_TOAST_ID } from 'blockscope/static/toastIds';

import { axiosErrorToast, successToast } from 'blockscope/utils/toastHandler';

export const contactUs_Action =
  (name, email, message, setLoading) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let body = { name, email, message };
    setLoading(true);
    try {
      await axios.post('/api/v2/misc/contact', body, config);
      setLoading(false);
      successToast('Message Sent', OTHER_ACTIONS_TOAST_ID.SUCCESS.CONTACT_US);
    } catch (err) {
      setLoading(false);
      axiosErrorToast(err, OTHER_ACTIONS_TOAST_ID.ERROR.CONTACT_US);
    }
  };
